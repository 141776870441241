import styled from 'styled-components';
import {
  DatePicker,
  Icons,
  SelectField,
  TimePicker,
} from '@flash-tecnologia/hros-web-ui-v2';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 23px;
  padding: 0 26px 80px 26px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 326px;
  @media screen and (max-width: 1000px) {
    max-width: 500px;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 40px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 981px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 380px) {
    padding: 20px;
  }
`;

const AdminCard = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
  max-width: 981px;

  border: 1px solid ${({ theme }) => theme.colors.feedback.info[70]};
  padding: 32px 24px;
  border-radius: 10px;
`;

const StyledTSelect = styled(SelectField)`
  && {
    width: 100%;
    max-width: 400px;
  }
  margin-top: 32px;
`;

const SelectContainer = styled.div`
  width: 100%;
  max-width: 640px;
`;

const StyledIcon = styled(Icons)<{ setColor?: string }>`
  && {
    ${({ setColor, theme }) => {
      let color = '';
      switch (setColor) {
        case 'neutral50':
          color = theme.colors.neutral[50];
          break;
        case 'primary':
          color = theme.colors.primary;
          break;
        case 'feedback50':
          color = theme.colors.feedback.info[50];
          break;
      }
      return `color: ${color}`;
    }};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  min-width: 64px;
  height: 64px;

  background: ${({ theme }) => theme.colors.feedback.info[90]};
  border-radius: 200px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 15px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;
    gap: 0px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 380px) {
    padding: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -9px;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  && {
    && {
      &.date-picker-container {
        width: 250px;
      }
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  width: 250px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 981px;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 380px) {
    padding: 20px;
  }
`;

export {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  SelectContainer,
  StyledTSelect,
  IconContainer,
  StyledIcon,
  FlexContainer,
  AdminCard,
  TextContainer,
  ContentContainer,
  DateContainer,
  StyledDatePicker,
  StyledTimePicker,
  OptionContainer,
};
