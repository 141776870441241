import styled from 'styled-components';

export const StyledFormGroup = styled.div`
  width: 100%;
  border: solid ${({ theme }) => theme.borders.width.xs}
    ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  padding: ${({ theme }) => theme.spacings.m};
`;

export const StyledRequiredSymbol = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error['50']};
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  width: 100%;
`;

export const StyledRequiredLabelWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  justify-content: flex-end;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.s};
`;
