import {
  StyledCloseIconContainer,
  StyledContentContainer,
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledModalContainer,
  StyledModalGlobalStyle,
} from './styles';
import { Modal as DSModal, PillButton } from '@flash-tecnologia/hros-web-ui-v2';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size: 'xs' | 'sm' | 'md' | 'full';
};

type ModalHeaderProps = {
  children: React.ReactNode;
};

type ModalContentProps = {
  children: React.ReactNode;
};

type ModalFooterProps = {
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> & {
  Header: React.FC<ModalHeaderProps>;
  Content: React.FC<ModalContentProps>;
  Footer: React.FC<ModalFooterProps>;
} = ({ isOpen, onClose, children, size }) => {
  if (!isOpen) return null;

  return (
    <DSModal.Root
      open={true}
      onClose={() => null}
      showClose={false}
      size={size}
    >
      <StyledModalContainer>
        <StyledModalGlobalStyle />
        <StyledCloseIconContainer>
          <PillButton
            onClick={onClose}
            icon="IconX"
            variant="default"
            type="secondary"
            size="extra-small"
          />
        </StyledCloseIconContainer>
        {children}
      </StyledModalContainer>
    </DSModal.Root>
  );
};

Modal.Header = ({ children }) => {
  return <StyledHeaderContainer>{children}</StyledHeaderContainer>;
};

Modal.Content = ({ children }) => {
  return <StyledContentContainer>{children}</StyledContentContainer>;
};

Modal.Footer = ({ children }) => {
  return <StyledFooterContainer>{children}</StyledFooterContainer>;
};

export { Modal };
