import styled, { createGlobalStyle } from 'styled-components';

export const StyledModalGlobalStyle = createGlobalStyle`
    .MuiModal-root {
        div:nth-child(3) {
           padding-top: 0px;
           padding-bottom: 0px;
        }
        div:nth-child(3):focus-visible {
            outline: none;
        }
    }
`;

export const StyledContentContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    `0 ${theme.spacings.m} ${theme.spacings.s} ${theme.spacings.m}`};
  overflow-y: auto;
  max-height: 60vh;
`;

export const StyledModalContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const StyledCloseIconContainer = styled.div`
  button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const StyledHeaderContainer = styled.div`
  padding: 24px 40px;
`;

export const StyledFooterContainer = styled.div`
  border-top: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  padding: 12px 40px;
`;
