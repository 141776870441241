import { useSectionsManager } from '@Hooks';

import { FieldsDataType, UpdateApiType } from '@Components/Sections/types';

type ManagerType = ReturnType<typeof useSectionsManager>;

type ModifiedFieldsArrayValue = [{ label: string; value: number; _id: string }];

type ModifiedFieldsType = {
  [key: string]: string | ModifiedFieldsArrayValue;
};

interface SubmitSections {
  type: UpdateApiType;
  api: ManagerType['api'];
  id: string;
  employeeId: string;
  fields: FieldsDataType[];
  modifiedFields: ModifiedFieldsType;
  initialValues?: { [key: string]: string };
}

export const submitSessions = async ({
  type,
  api,
  id,
  employeeId,
  fields,
  modifiedFields,
  initialValues,
}: SubmitSections) => {
  if (type === 'CustomFields') {
    return api.updateEmployeeCustomFields({
      employeeId,
      data: Object.keys(modifiedFields).map((key) => {
        const field = fields.find((f) => f.key === key);
        return {
          sectionId: field!.sectionId!,
          fieldId: key,
          type: field!.type as string,
          value: modifiedFields[key],
        };
      }),
    });
  }

  if (type === 'AddressData') {
    return api.updateEmployeeAddressData({
      employeeId,
      address: {
        zipCode: (modifiedFields.zipCode || initialValues?.zipCode) as string,
        street: (modifiedFields.street || initialValues?.street) as string,
        number: (modifiedFields.number || initialValues?.number) as string,
        district: (modifiedFields.district ||
          initialValues?.district) as string,
        state: (modifiedFields.state || initialValues?.state) as string,
        city: (modifiedFields.city || initialValues?.city) as string,
        complement: (modifiedFields.complement ||
          initialValues?.complement ||
          '') as string,
      },
    });
  }

  if (type === 'BankingData') {
    return api.updateEmployeeBankingData({
      employeeId,
      banking: {
        bankName: (modifiedFields.bankName ||
          initialValues?.bankName) as string,
        accountCheckDigit: (modifiedFields.accountCheckDigit ||
          initialValues?.accountCheckDigit) as string,
        accountNumber: (modifiedFields.accountNumber ||
          initialValues?.accountNumber) as string,
        accountType: (modifiedFields.accountType ||
          initialValues?.accountType) as string,
        branch: (modifiedFields.branch || initialValues?.branch) as string,
      },
    });
  }

  if (type === 'ComplementaryData') {
    return api.updateEmployeeComplementaryData({
      employeeId,
      data: {
        gender: modifiedFields.gender as string,
        race: modifiedFields.race as string,
        maritalStatus: modifiedFields.maritalStatus as string,
        nationality: modifiedFields.nationality as string,
        socialName: modifiedFields.socialName as string,
      },
    });
  }

  if (type === 'BasicData') {
    return api.updateEmployeeBasicData({
      employeeId,
      data: {
        name: modifiedFields?.name as string,
        email: modifiedFields?.email as string,
        phoneNumber: modifiedFields?.phone
          ?.toString()
          ?.replace(/[^0-9]/g, '') as string,
      },
    });
  }

  if (type === 'ProfessionalData') {
    return api.updateEmployeeBasicData({
      employeeId,
      data: {
        corporateEmail: modifiedFields?.corporateEmail as string,
        externalId: modifiedFields?.externalId as string,
        managerId: (modifiedFields?.manager as any)?._id as string,
        pis: modifiedFields?.pis as string,
        startDate: modifiedFields?.startDate as string,
        terminationDate: (modifiedFields?.terminationDate as string) || null,
      },
    });
  }

  if (type === 'CreateDependent') {
    return api.addEmployeeDependent({
      employeeId,
      data: {
        name: modifiedFields?.name as string,
        kinship: modifiedFields?.kinship as string,
        birthDate: modifiedFields?.birthDate as string,
      },
    });
  }

  if (type === 'DeleteDependent') {
    return api.deleteEmployeeDependent({
      employeeId,
      dependentId: id,
    });
  }

  if (type === 'UpdateDependent') {
    return api.updateEmployeeDependent({
      employeeId,
      dependentId: id,
      data: {
        name: modifiedFields?.name as string,
        kinship: modifiedFields?.kinship as string,
        birthDate: modifiedFields?.birthDate as string,
      },
    });
  }
};
