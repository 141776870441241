import { StyledTag } from './styled';
import { StatusEnum } from '../../common/types/StatusEnum';

export const StatusTag = ({ status }: { status: StatusEnum }) => {
  switch (status) {
    case StatusEnum.ACTIVE:
      return (
        <StyledTag variant="success" hasLeftDotIcon={true}>
          Ativo
        </StyledTag>
      );

    case StatusEnum.INACTIVE:
      return (
        <StyledTag variant="error" hasLeftDotIcon={true}>
          Inativo
        </StyledTag>
      );

    case StatusEnum.INVITATION_SCHEDULED:
      return (
        <StyledTag variant="gray" hasLeftDotIcon={true}>
          Convite agendado
        </StyledTag>
      );

    case StatusEnum.INVITATION_SENT:
      return (
        <StyledTag variant="gray" hasLeftDotIcon={true}>
          Convite enviado
        </StyledTag>
      );

    case StatusEnum.INVITATION_EXPIRED:
      return (
        <StyledTag variant="gray" hasLeftDotIcon={true}>
          Convite expirado
        </StyledTag>
      );

    case StatusEnum.INVITATION_PENDING:
      return (
        <StyledTag variant="info" hasLeftDotIcon={true}>
          Aguardando convite
        </StyledTag>
      );

    default:
      return <></>;
  }
};
