import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledErrorCaption = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 4px 0px 0px 16px;
`;

export const StyledCaption = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledCaptionIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[50]};
`;
