import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledCaption, StyledCaptionIcon, StyledErrorCaption } from './styled';
import { ReactNode } from 'react';

type ErrorCaptionProps = {
  children: ReactNode;
};

export const ErrorCaption = ({ children }: ErrorCaptionProps) => {
  return (
    <StyledErrorCaption>
      <StyledCaptionIcon name="IconInfoCircle" size={18} />
      <StyledCaption variant="caption" weight={600}>
        {children}
      </StyledCaption>
    </StyledErrorCaption>
  );
};
