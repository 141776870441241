import {
  DatePicker,
  Divider,
  LinkButton,
  Spinner,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledAdditionalFieldsContainer,
  StyledContentContainer,
  StyledLabel,
  StyledLabelContainer,
  StyledLinkContainer,
  StyledLinkIcon,
  StyledRequiredContainer,
  StyledRequiredLabel,
  StyledRequiredSymbol,
  StyledSpinnerContainer,
  StyledTitleContainer,
} from './styles';
import { FormType } from './components/FormType';
import { DocumentNumberField } from './components/DocumentNumberField';
import withAdditionalLabel from '@/pages/PageTable/components/ModalCreateEmployee/hocs/WithAdditionalLabel';
import { FormTypeEnum, useForm } from '../../context/FormContext';
import { TextFieldWithCheckBox } from '@/pages/PageTable/components/ModalCreateEmployee/hocs/InputFieldWithCheckBox';
import { WarningCard } from './components/WarningCard';
import { ErrorCaption } from './components/ErrorCaption';
import { SelectRoleField } from './components/SelectRoleField';
import { SelectGroupField } from './components/SelectGroupField';
import { SelectDepartmentField } from './components/SelectDepartmentField';
import { SelectManagerField } from './components/SelectManagerField';
import { SelectPermissionField } from './components/SelectPermissionField';
import { useChangeEmployeeCompany } from '@/hooks/useChangeEmployeeCompany';
import dispatchToast from '@utils/dispatchToast';
import { InvitationCard } from './components/InvitationCard';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

const TextFieldWithAdditionalLabel = withAdditionalLabel(TextField);
const TextFieldWithAdditionalLabelAndCheckBox = withAdditionalLabel(
  TextFieldWithCheckBox,
);

type CreateEmployeeModalContent = {
  onClose: () => void;
};

export const CreateEmployeeModalContent = ({
  onClose,
}: CreateEmployeeModalContent) => {
  const {
    errors,
    updateField,
    form,
    allFieldsDisabled,
    disableEmailField,
    setDisableEmailField,
    setErrors,
    formType,
    employeeIdForTransfer,
    canMigrate,
    canRegister,
  } = useForm();

  const { selectedCompany } = useSelectedCompany();

  const { changeEmployeeCompany, isLoading } = useChangeEmployeeCompany({
    options: {
      onSuccess: () => {
        dispatchToast({
          type: 'success',
          title: 'Pessoa transferida com sucesso!',
          content:
            'O colaborador foi transferido da empresa de origem e cadastrada nesta empresa com sucesso!',
        });

        onClose();
      },
      onError: () => {
        dispatchToast({
          type: 'error',
          title: 'Erro ao transferir pessoa',
          content:
            'Não foi possível transferir a pessoa para esta empresa. Por favor, tente novamente.',
        });
      },
    },
  });

  return (
    <StyledContentContainer>
      <FormType />
      <StyledTitleContainer>
        <Typography variant="headline9" weight={700}>
          Dados para envio do convite
        </Typography>
        <StyledRequiredContainer>
          <StyledRequiredSymbol>*</StyledRequiredSymbol>
          <StyledRequiredLabel variant="body4" weight={600}>
            Campos obrigatórios
          </StyledRequiredLabel>
        </StyledRequiredContainer>
      </StyledTitleContainer>
      <DocumentNumberField />
      {canMigrate && (
        <WarningCard
          title="Pessoa já cadastrada no grupo econômico"
          description='Este CPF já está vinculado a outra empresa do mesmo grupo econômico.
          Você pode transferir a pessoa para esta empresa ou mantê-la na empresa
          atual. Para transferi-la, clique em "Transferir para esta empresa".'
          action={
            <StyledLinkContainer>
              <LinkButton
                onClick={() =>
                  changeEmployeeCompany(
                    employeeIdForTransfer,
                    selectedCompany.id,
                  )
                }
                variant="error"
              >
                {isLoading ? (
                  <StyledSpinnerContainer>
                    <Spinner variant="error" size={16} />
                  </StyledSpinnerContainer>
                ) : (
                  <StyledLinkIcon size={18} name="IconSwitch3" />
                )}
                {isLoading
                  ? 'Transferindo colaborador...'
                  : 'Transferir para esta empresa'}
              </LinkButton>
            </StyledLinkContainer>
          }
        />
      )}

      {canMigrate === false && (
        <WarningCard
          title="O colaborador não pode ser migrado."
          description="Esse colaborador não pode ser migrado. Por favor, entre em contato com o suporte para mais informações sobre como proceder."
        />
      )}

      {!canRegister === false && (
        <WarningCard
          title="O colaborador não pode ser registrado."
          description="O colaborador não pode ser cadastrado porque já há um colaborador com o mesmo CPF nesta empresa."
        />
      )}

      <TextFieldWithAdditionalLabel
        additionalLabel={
          errors.name && <ErrorCaption>{errors.name}</ErrorCaption>
        }
        fullWidth
        label="Nome Completo"
        placeholder="Nome Completo"
        required={!errors.name}
        onChange={(e) => updateField('name', e.target.value)}
        value={form.name}
        error={!!errors['name']}
        disabled={allFieldsDisabled}
      />
      <TextFieldWithAdditionalLabelAndCheckBox
        fullWidth
        additionalLabel={
          errors.email ? (
            <ErrorCaption>{errors.email}</ErrorCaption>
          ) : (
            <StyledLabelContainer>
              <StyledLabel variant="caption">
                Enviaremos o convite de primeiro acesso à plataforma e app da
                Flash para esse e-mail. Caso não possua o e-mail da pessoa,
                marque a opção correspondente e preencha o celular para que o
                convite seja enviado por SMS.
              </StyledLabel>
            </StyledLabelContainer>
          )
        }
        label="E-mail para envio do convite"
        disabled={disableEmailField || allFieldsDisabled}
        disableCheckbox={allFieldsDisabled}
        placeholder="E-mail"
        onCheckBoxChange={() => {
          setDisableEmailField((disableEmailField) => !disableEmailField);
          if (!disableEmailField) {
            updateField('email', '');
            setErrors((prevError) => ({
              ...prevError,
              email: undefined,
            }));
          }
        }}
        checkboxLabel="Não sei o e-mail"
        required={!disableEmailField && !errors.email}
        onChange={(e) => updateField('email', e.target.value)}
        value={form.email}
        error={!!errors['email']}
      />
      <TextFieldWithAdditionalLabel
        additionalLabel={
          errors.phone && <ErrorCaption>{errors.phone}</ErrorCaption>
        }
        fullWidth
        label="Celular"
        placeholder="Celular"
        disabled={allFieldsDisabled}
        required={disableEmailField && !errors.phone}
        imaskProps={{
          mask: '+55 00 90000-0000',
        }}
        onChange={(e) => updateField('phone', e.target.value)}
        value={form.phone}
        error={!!errors['phone']}
      />
      {formType === FormTypeEnum.FULL && (
        <StyledAdditionalFieldsContainer>
          <DatePicker
            value={form.admissionDate}
            disabled={allFieldsDisabled}
            label="Data de admissão"
            onDateChange={(value) =>
              updateField('admissionDate', value?.toISOString())
            }
          />
          <SelectRoleField />
          <SelectGroupField />
          <SelectDepartmentField />
          <TextField
            disabled={allFieldsDisabled}
            label="PIS"
            value={form.pis}
            onChange={(e) => updateField('pis', e.target.value)}
            imaskProps={{
              mask: '000.00000.00-0',
            }}
          />
          <SelectManagerField />
          <SelectPermissionField />
        </StyledAdditionalFieldsContainer>
      )}
      <Divider orientation="horizontal" />
      <InvitationCard />
    </StyledContentContainer>
  );
};
