import { useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import {
  IconButton,
  LinkButton,
  Modal,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import {
  MainContainer,
  HeaderContainer,
  FooterContainer,
  StyledButton,
  IconContainer,
  StyledIcon,
  StyledModalGlobalStyle,
} from './styled';
import { worksheetComponentInfo } from '../../componentsText';
import { useAbortImport } from '../../hooks/useAbortImport';
import { getWorksheetUrl } from '../../helpers/getWorksheetUrl';
import { ImportType, EmployeesWorksheetSteps } from '../../types';

interface IAbortImportProps {
  navigate: NavigateFunction;
  operation: ImportType;
  setOpen: boolean;
  onClose: () => void;
  importId: string;
  onInterrupt: () => void;
}

export const AbortImportProcess = ({
  navigate,
  operation,
  setOpen,
  onClose,
  importId,
  onInterrupt,
}: IAbortImportProps) => {
  const { abortImportProcess, isLoading } = useAbortImport();

  useEffect(() => {
    if (isLoading === false) {
      onInterrupt();
      onClose();
      navigate(
        getWorksheetUrl(operation, EmployeesWorksheetSteps.importStatus),
        { state: { refresh: true } },
      );
    }
  }, [isLoading]);

  const Header = (
    <HeaderContainer>
      <IconButton
        variant="line"
        size="medium"
        icon={'IconX'}
        onClick={onClose}
      />
    </HeaderContainer>
  );

  const Footer = (
    <FooterContainer>
      <LinkButton
        variant="neutral"
        style={{ alignSelf: 'center' }}
        onClick={onClose}
      >
        Voltar para importação
      </LinkButton>
      <StyledButton
        variantType="error"
        variant="primary"
        loading={isLoading}
        onLoading={isLoading}
        size="large"
        style={{ alignSelf: 'center' }}
        onClick={async () => {
          await abortImportProcess(importId);
          onClose();
        }}
      >
        {`${worksheetComponentInfo[operation].cancelProcessment.button}`}
      </StyledButton>
    </FooterContainer>
  );

  return (
    <Modal.Root
      header={Header}
      footer={Footer}
      open={setOpen}
      onClose={onClose}
    >
      <MainContainer>
        <StyledModalGlobalStyle />
        <IconContainer>
          <StyledIcon
            name="IconAlertCircle"
            fill="none"
            size={52}
            stroke={'1.2px'}
          />
        </IconContainer>
        <StyledText
          setColor="error40"
          variant="body3"
          style={{ fontWeight: '700' }}
        >
          {`${worksheetComponentInfo[operation].cancelProcessment.title}`}
        </StyledText>
        <StyledTitle
          setColor="neutral50"
          variant="body1"
          style={{ textAlign: 'center' }}
        >
          {`${worksheetComponentInfo[operation].cancelProcessment.confirm}`}
        </StyledTitle>
        <StyledText
          setColor="neutral50"
          variant="body3"
          style={{ textAlign: 'center' }}
        >
          {`${worksheetComponentInfo[operation].cancelProcessment.warning}`}
        </StyledText>
      </MainContainer>
    </Modal.Root>
  );
};
