import styled from 'styled-components';

import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

const StyledTitle = styled(Typography)<{ setColor?: string }>`
  && {
    ${({ setColor, theme }) => {
      let color = '';
      switch (setColor) {
        case 'secondary50':
          color = theme.colors.secondary[50];
          break;
        case 'neutral70':
          color = theme.colors.neutral[70];
          break;
        case 'neutral30':
          color = theme.colors.neutral[30];
          break;
        case 'neutral20':
          color = theme.colors.neutral[20];
          break;
        case 'neutral10':
          color = theme.colors.neutral[10];
          break;
        case 'feedback40':
          color = theme.colors.feedback.error[40];
          break;
        case 'feedback50':
          color = theme.colors.feedback.error[50];
          break;
      }
      return `color: ${color}`;
    }};
    font-weight: 700;
  }
`;

const StyledText = styled(Typography)<{ setColor: string }>`
  && {
    ${({ setColor, theme }) => {
      let color = '';
      switch (setColor) {
        case 'error40':
          color = theme.colors.feedback.error[40];
          break;
        case 'info10':
          color = theme.colors.feedback.info[10];
        case 'neutral50':
          color = theme.colors.neutral[50];
          break;
        case 'neutral20':
          color = theme.colors.neutral[20];
          break;
        case 'neutral70':
          color = theme.colors.neutral[70];
          break;
        case 'secondary50':
          color = theme.colors.secondary[50];
          break;
      }
      return `color: ${color}`;
    }}
  }
`;

const ErrorText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error[50]};
    font-size: 12px;
    font-weight: 700;
  }
`;

const HelperTextContainer = styled.div`
  display: flex;
  gap: 5px;

  align-items: center;
`;

export { StyledTitle, StyledText, ErrorText, HelperTextContainer };
