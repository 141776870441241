import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useFindCompanyDepartments = () => {
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.findCompanyDepartments.useQuery({
    companyId: selectedCompany.id,
  });

  return {
    departments: data,
    isLoading,
  };
};
