import { Tag } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-bottom: 32px;
  align-items: center;
`;

export const TagStyled = styled(Tag)((props) => ({
  height: props.theme.spacings.xs,
  padding: `${props.theme.spacings.xs5} ${props.theme.spacings.xs3}`,
  pointerEvents: 'none',
}));
