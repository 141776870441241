import { ImportStatus, ImportType } from '@/pages/PageEmployeesWorksheet/types';
import { Card, IconContainer, StyledIcon, TextContainer } from '../../styled';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { worksheetComponentInfo } from '@/pages/PageEmployeesWorksheet/componentsText';
import { FooterFeedback } from '../Footer/footer-feedback';
import { NavigateFunction } from 'react-router-dom';

interface IAbortedFeedback {
  navigate: NavigateFunction;
  operation: ImportType;
  importStatus: ImportStatus;
  successRowsQt: number;
  failedRowsQt: number;
  newImportUrl?: string;
  redirectBackTo?: string;
}

export const AbortedFeedback = ({
  navigate,
  operation,
  importStatus,
  successRowsQt,
  failedRowsQt,
  newImportUrl,
  redirectBackTo,
}: IAbortedFeedback) => {
  return (
    <Card>
      <TextContainer>
        <IconContainer setColor="error90">
          <StyledIcon
            name="IconAlertTriangle"
            fill="none"
            setColor="error50"
            size={32}
          />
        </IconContainer>
        <StyledTitle
          setColor="neutral30"
          variant="body1"
          children="Processamento interrompido"
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={
            successRowsQt === 1
              ? `${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.success.singular}`
              : `${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.success.plural}`
          }
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={
            failedRowsQt === 1
              ? `${failedRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.error.singular}`
              : `${failedRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.error.plural}`
          }
        />
      </TextContainer>
      <FooterFeedback
        navigate={navigate}
        importStatus={importStatus}
        operation={operation}
        setOpenAbortModal={() => {}}
        newImportUrl={newImportUrl}
        redirectBackTo={redirectBackTo}
      />
    </Card>
  );
};
