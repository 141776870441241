import { Button, Icons as FIcons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  flex: 1;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const Icons = styled(FIcons)`
  && {
    color: ${({ theme }) => theme.colors.neutral[40]};
  }
`;

export const ActionButton = styled(Button)`
  border-radius: ${({ theme }) => theme.borders.radius.pill} !important;
`;

export const ActionButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
