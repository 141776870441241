import { Modal } from '@Components/Modal';
import {
  StyledCellContainer,
  StyledCellDescription,
  StyledCellDescriptionContainer,
  StyledCellIcon,
  StyledCellTextContainer,
  StyledCellTitle,
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledIcon,
  StyledIconContainer,
  StyledIconLabel,
  StyledModalHeaderDescription,
  StyledModalHeaderTitle,
  StyledSuccessContainer,
  StyledTableContainer,
} from './styles';
import {
  Button,
  DataGrid,
  GenericProfilePicture,
  Icons,
  LinkButton,
  Table,
  tableControllers,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useEffect, useMemo, useState } from 'react';
import { trpc } from '@/api/client';

type ModalEmployeeMigrationSuccessProps = {
  isOpen: boolean;
  onClose: () => void;
  employeeId: string;
};

const TABLE_SIZE = 200;

export const ModalEmployeeMigrationSuccess = ({
  isOpen,
  onClose,
  employeeId,
}: ModalEmployeeMigrationSuccessProps) => {
  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <StyledHeaderContainer>
          <StyledSuccessContainer>
            <StyledIconContainer>
              <StyledIcon name="IconCircleCheck" size={28} />
            </StyledIconContainer>
            <StyledIconLabel variant="body3" weight={700}>
              Tudo certo!
            </StyledIconLabel>
          </StyledSuccessContainer>
          <StyledModalHeaderTitle variant="headline8" weight={700}>
            Movimentação realizada com sucesso!
          </StyledModalHeaderTitle>
        </StyledHeaderContainer>
      </Modal.Header>
      <Modal.Footer>
        <StyledFooterContainer>
          <LinkButton onClick={() => onClose()} variant="neutral">
            Fechar
          </LinkButton>
          <Button onClick={() => onClose()} variant="primary" size="medium">
            Continuar
            <Icons name="IconArrowRight" />
          </Button>
        </StyledFooterContainer>
      </Modal.Footer>
    </Modal>
  );
};
