import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 100%;
`;

export const StyledForms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-bottom: 40px;
`;

export const StyledOptionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
