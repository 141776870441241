import { Modal } from '@Components/Modal';
import {
  StyledSuccessContainer,
  StyledDescription,
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledIcon,
  StyledIconContainer,
  StyledTextContainer,
  StyledTitle,
  StyledWarningLabel,
  StyledContentContainer,
} from './styles';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { CopyLink } from '../CopyLink';

type ModalWarningProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  description: string;
  invitationCode: string;
};

export const ModalInvitationCode = ({
  isOpen,
  onClose,
  title,
  description,
  onSubmit,
  invitationCode,
}: ModalWarningProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <Modal.Header>
        <StyledHeaderContainer>
          <StyledSuccessContainer>
            <StyledIconContainer>
              <StyledIcon name="IconCircleCheck" size={28} />
            </StyledIconContainer>
            <StyledWarningLabel variant="body3" weight={700}>
              Tudo certo!
            </StyledWarningLabel>
          </StyledSuccessContainer>
        </StyledHeaderContainer>
      </Modal.Header>
      <Modal.Content>
        <StyledContentContainer>
          <StyledTextContainer>
            <StyledTitle variant="headline7" weight={700}>
              {title}
            </StyledTitle>
            <StyledDescription variant="body3" weight={400}>
              {description}
            </StyledDescription>
          </StyledTextContainer>
          <CopyLink code={invitationCode} />
        </StyledContentContainer>
      </Modal.Content>
      <Modal.Footer>
        <StyledFooterContainer>
          <Button
            onClick={() => onSubmit()}
            variant="primary"
            variantType="default"
            size="large"
          >
            Concluir <Icons name="IconCheck" />
          </Button>
        </StyledFooterContainer>
      </Modal.Footer>
    </Modal>
  );
};
