import {
  Import,
  ImportType,
  ImportStatus,
} from '@/pages/PageEmployeesWorksheet/types';
import { worksheetComponentInfo } from '@/pages/PageEmployeesWorksheet/componentsText';

import {
  Card,
  IconContainer,
  StyledIcon,
  TextContainer,
  InfoContainer,
} from '../../styled';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { FooterFeedback } from '../Footer/footer-feedback';
import { NavigateFunction } from 'react-router-dom';

interface IProcessingFeedback {
  navigate: NavigateFunction;
  operation: ImportType;
  importProcess: Import | null;
  importStatus: ImportStatus;
  setOpenAbortModal: (input: boolean) => void;
  newImportUrl?: string;
  redirectBackTo?: string;
}
export const ProcessingFeedback = ({
  navigate,
  operation,
  importProcess,
  setOpenAbortModal,
  importStatus,
  newImportUrl,
  redirectBackTo,
}: IProcessingFeedback) => {
  return (
    <Card>
      <TextContainer>
        <IconContainer setColor="secondary95">
          <StyledIcon
            name="IconRefresh"
            fill="none"
            setColor="primary"
            size={32}
            loading={true}
          />
        </IconContainer>
        <StyledTitle
          setColor="secondary50"
          variant="body1"
          children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.title}`}
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.description}`}
        />
      </TextContainer>
      <InfoContainer>
        <StyledText
          setColor="neutral20"
          variant="body3"
          children={importProcess?.fileName}
          style={{ fontWeight: 'bolder' }}
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={
            importProcess &&
            importProcess?.totalRows > 0 &&
            importProcess.processedRows > 0
              ? `${importProcess?.processedRows || '0'} de ${
                  importProcess?.totalRows
                } ${
                  worksheetComponentInfo[operation].processmentStatusPage
                    .mainCard.processing.plural
                }`
              : null
          }
        />
      </InfoContainer>
      <FooterFeedback
        navigate={navigate}
        importStatus={importStatus}
        operation={operation}
        setOpenAbortModal={setOpenAbortModal}
        newImportUrl={newImportUrl}
        redirectBackTo={redirectBackTo}
      />
    </Card>
  );
};
