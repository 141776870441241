import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useFindCompanyGroups = () => {
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.findCompanyGroups.useQuery({
    companyId: selectedCompany.id,
  });

  return {
    groups: data,
    isLoading,
  };
};
