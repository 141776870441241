import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledRadioWrapper, StyledTypeFormContainer } from './styles';
import { RadioOption } from '@Components/RadioOption';
import { FormTypeEnum, useForm } from '../../../../context/FormContext';

export const FormType = () => {
  const { formType, selectFormType, allFieldsDisabled } = useForm();

  return (
    <StyledTypeFormContainer>
      <Typography variant="headline9" weight={700}>
        Tipo de cadastro
      </Typography>
      <StyledRadioWrapper>
        <RadioOption
          title="Cadastro simples"
          description="Dados básicos para cadastro da sua equipe nas plataformas Flash"
          value={FormTypeEnum.SIMPLE}
          checked={formType === FormTypeEnum.SIMPLE}
          onChange={() => selectFormType(FormTypeEnum.SIMPLE)}
          disabled={allFieldsDisabled}
        />
        <RadioOption
          title="Cadastro completo"
          description="Inclui informações adicionais essenciais para funcionamento de outros módulos"
          value={FormTypeEnum.FULL}
          checked={formType === FormTypeEnum.FULL}
          onChange={() => selectFormType(FormTypeEnum.FULL)}
          disabled={allFieldsDisabled}
        />
      </StyledRadioWrapper>
    </StyledTypeFormContainer>
  );
};
