import { ErrorBoundary } from '@utils/ErrorBoundary';
import dispatchToast from '@utils/dispatchToast';
import { trpc } from '@/api/client';

export const useAbortImport = () => {
  const { mutateAsync, isLoading } = trpc.abortImport.useMutation();

  const abortImportProcess = async (importId: string) => {
    await mutateAsync(
      { importId },
      {
        onError(error) {
          ErrorBoundary.captureException({
            ...error,
            name: 'AbortImportError',
          });
          dispatchToast({
            type: 'error',
            content: 'Ocorreu um erro ao tentar interromper processamento.',
          });
        },
      },
    );
  };

  return {
    abortImportProcess,
    isLoading,
  };
};
