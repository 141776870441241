import { useMemo } from 'react';
import { StyledSelectInput } from './styles';
import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useMultipleMigrationContext } from '@/pages/PageMultipleMigration/context/MultipleMigration.context';

export const SelectCompanyField = () => {
  const { data: companies } = trpc.getCompanies.useQuery();
  const { selectedCompany } = useSelectedCompany();
  const { setSelectedCompany } = useMultipleMigrationContext();

  const options = useMemo(
    () =>
      companies
        ? companies
            .filter((company) => company.id !== selectedCompany.id)
            .map(
              (company: {
                name: string;
                id: string;
                registrationNumber: string;
              }) => ({
                label: company.name,
                value: company.id,
                caption: company.registrationNumber,
              }),
            )
        : [],
    [companies],
  );

  return (
    <StyledSelectInput
      onSelectChange={(_, value) =>
        setSelectedCompany({
          id: value.value,
          name: value.label,
          registrationNumber: value.caption,
        })
      }
      fullWidth
      label="Selecione a empresa de destino"
      options={options}
      required
    />
  );
};
