import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
  PageTable,
  PagePersonManage,
  PageEmployeesWorksheet,
  PageAccount,
} from '../pages';
import { RoutesGuard } from '@/routes/routesGuard';
import { EmployeeRedirect } from '@Components/EmployeeRedirect';
import { PageMultipleMigration } from '@/pages/PageMultipleMigration';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/employees/person"
          element={<Navigate to={'/employees/me/profile'} />}
        />
        <Route path="/employees/:id/manage" element={<EmployeeRedirect />} />
        <Route path="/employees/:id/profile" element={<PagePersonManage />} />
        <Route
          element={<RoutesGuard permission="core_manage_employees_list" />}
        >
          <Route path="/employees/account" element={<PageAccount />} />
          <Route
            path="/employees/multiple-migration"
            element={<PageMultipleMigration />}
          />
          <Route path="/employees" element={<PageTable />} />
          <Route
            path="/employees/import-sheet/:operation/:step"
            element={<PageEmployeesWorksheet />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
