import { Modal } from '@Components/Modal';
import { CreateEmployeeModalHeader } from './components/Header';
import { FormProvider, FormTypeEnum, useForm } from './context/FormContext';
import { CreateEmployeeModalContent } from './components/Content';
import { Footer } from './components/Footer';
import { ModalInvitationCode } from './components/ModalInvitationCode';
import { useState } from 'react';

type ModalCreateEmployeeProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ModalCreateEmployee = ({
  isOpen,
  onClose,
}: ModalCreateEmployeeProps) => {
  const [invitationCode, setInvitationCode] = useState('');
  const [openInvitationCodeModal, setOpenInvitationCodeModal] = useState(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <FormProvider>
          <Modal.Header>
            <CreateEmployeeModalHeader />
          </Modal.Header>
          <Modal.Content>
            <CreateEmployeeModalContent onClose={onClose} />
          </Modal.Content>
          <Modal.Footer>
            <Footer
              onClose={onClose}
              onCreateInviteCode={(code) => {
                setInvitationCode(code);
                setOpenInvitationCodeModal(true);
              }}
            />
          </Modal.Footer>
        </FormProvider>
      </Modal>
      <ModalInvitationCode
        isOpen={openInvitationCodeModal}
        onClose={() => setOpenInvitationCodeModal(false)}
        onSubmit={() => setOpenInvitationCodeModal(false)}
        invitationCode={invitationCode}
        title="Pessoa cadastrada com sucesso!"
        description="Você também pode copiar o link de primeiro acesso e enviar diretamente para a pessoa:"
      />
    </>
  );
};
