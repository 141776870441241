import { PermissionProfilesType } from 'src/pages/PageTable/types';
import { MenuAddColab } from './components/MenuAddColab';
import { HeaderButtonsWrapper } from './styled';
import { MenuOptions } from './components/MenuOptions';

export const HeaderButtons = ({ menuDisabled }: PermissionProfilesType) => {
  return (
    <HeaderButtonsWrapper>
      <MenuAddColab disabled={menuDisabled} />
      <MenuOptions />
    </HeaderButtonsWrapper>
  );
};
