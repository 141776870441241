import { useFindCompanyDepartments } from '@/hooks/useFindCompanyDepartments';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { useEffect, useMemo } from 'react';
import { useForm } from '../../../../context/FormContext';

export const SelectDepartmentField = () => {
  const { departments } = useFindCompanyDepartments();
  const { updateField, allFieldsDisabled } = useForm();

  const options = useMemo(
    () =>
      departments
        ? departments.map((department) => ({
            label: department.name,
            value: department.id,
          }))
        : [],
    [departments],
  );

  return (
    <SelectField
      disabled={allFieldsDisabled}
      options={options}
      fullWidth
      label="Departamento"
      onSelectChange={(_, department) =>
        updateField('departmentId', department.value)
      }
    />
  );
};
