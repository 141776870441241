import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { LinkButton, Radio } from '@flash-tecnologia/hros-web-ui-v2';

import { XLSXReader } from '@Utils/XLSXReader';

import { ButtonArea } from '../../../components';
import { HeaderHelperModal, CellHelperModal } from '../../../modals';

import {
  PageContainer,
  LeftColumn,
  RightColumn,
  Card,
  TextContainer,
  ContentContainer,
  FlexContainer,
  StyledIcon,
  HeaderContainer,
  BodyContainer,
  StyledTextField,
  InputContainer,
  LeftTable,
  RightTable,
} from './styled';

import {
  ErrorText,
  HelperTextContainer,
  StyledText,
  StyledTitle,
} from 'src/common/styles/general-styles';
import { getWorksheetUrl } from '@/pages/PageEmployeesWorksheet/helpers/getWorksheetUrl';
import {
  EmployeesWorksheetSteps,
  ImportType,
} from '@/pages/PageEmployeesWorksheet/types';
import { worksheetComponentInfo } from '@/pages/PageEmployeesWorksheet/componentsText';

interface ExcelFilePageProps {
  operation: ImportType;
}

export const ExcelFilePage = ({ operation }: ExcelFilePageProps) => {
  const navigate = useNavigate();
  const state = useLocation() as any;

  state.state === null &&
    (() =>
      navigate(
        getWorksheetUrl(operation, EmployeesWorksheetSteps.uploadFile),
      ))();

  const currentState = useMemo(() => state.state, [state]);

  const [openHeaderModal, setOpenHeaderModal] = useState(false);
  const [openCellModal, setOpenCellModal] = useState(false);
  const [handleCellValue, setHandleCellValue] = useState('');
  const [handleDataTable, setHandleDataTable] = useState([]);
  const [emptyCell, setEmptyCell] = useState(false);

  const validationSchema = yup.object({
    hasHeaderRadio: yup.boolean().required('Esse dado deve ser indicado.'),
    hasntHeaderRadio: yup.boolean().required('Esse dado deve ser indicado.'),
    initialCell: yup.string().required('Informe a célula.'),
  });

  const formik = useFormik({
    initialValues: {
      hasHeaderRadio: false,
      hasntHeaderRadio: false,
      initialCell: '',
    },
    validationSchema: validationSchema,

    validate: () => {
      let errors: any = {};
      if (emptyCell) {
        errors.initialCell = '';
      }
      return errors;
    },

    onSubmit: () => {
      navigate(
        getWorksheetUrl(operation, EmployeesWorksheetSteps.dataAssociation),
        {
          state: {
            ...currentState,
            dataTable: handleDataTable,
            hasHeader: formik.values.hasHeaderRadio,
            initialCell: formik.values.initialCell,
            defaultConfig: false,
            separator: ',',
          },
        },
      );
    },
  });

  useEffect(() => {
    (async () => {
      const hasHeader = formik.values.hasHeaderRadio;
      const initialCell = formik.values.initialCell;
      if (hasHeader === null || !initialCell || initialCell.length < 2) {
        setHandleDataTable([]);
        setHandleCellValue('');
        return;
      }
      const cellColumn = initialCell.replace(/[^A-Za-z]/g, '');
      const cellLine = parseInt(initialCell.replace(/\D/g, ''));
      const columnToNumber = cellColumn.toLowerCase().charCodeAt(0) - 96;

      const result = (await XLSXReader({
        droppedFiles: currentState.droppedFile,
      })) as any;
      setHandleDataTable(result);

      if (result.length < cellLine) {
        setHandleCellValue('');
        setEmptyCell(true);
        return;
      }
      try {
        const resultToArray = Object.values(result[cellLine - 1]);
        const cellValue = resultToArray[columnToNumber - 1];
        if (cellValue) {
          setEmptyCell(false);
          setHandleCellValue(cellValue.toString());
        } else {
          setHandleCellValue('');
          setEmptyCell(true);
        }
      } catch (e) {
        setHandleCellValue('');
        setEmptyCell(true);
      }
    })();
  }, [formik.values.hasHeaderRadio, formik.values.initialCell]);

  return (
    <>
      <PageContainer>
        <LeftColumn>
          <StyledTitle
            setColor="secondary50"
            variant="body1"
            children={worksheetComponentInfo[operation].excelFilePage.title}
          />
          <StyledText
            setColor="neutral50"
            variant="body3"
            children={
              worksheetComponentInfo[operation].excelFilePage.description
            }
          />
        </LeftColumn>
        <RightColumn>
          <Card>
            <TextContainer>
              <StyledTitle
                setColor="neutral30"
                variant="body1"
                children={
                  worksheetComponentInfo[operation].excelFilePage.card.title
                }
              />
              <StyledText
                setColor="neutral50"
                variant="body4"
                children={
                  worksheetComponentInfo[operation].excelFilePage.card
                    .description
                }
              />
            </TextContainer>
            <FlexContainer>
              <ContentContainer>
                <Radio
                  size="small"
                  name="hasHeaderRadio"
                  checked={formik.values.hasHeaderRadio}
                  value={formik.values.hasHeaderRadio}
                  onChange={() => {
                    formik.setValues({
                      hasHeaderRadio: true,
                      hasntHeaderRadio: false,
                      initialCell: formik.values.initialCell || '',
                    });
                  }}
                  error={
                    formik.touched.hasHeaderRadio &&
                    Boolean(formik.errors.hasHeaderRadio)
                  }
                />
                <StyledText
                  setColor="neutral50"
                  variant="body4"
                  children="Sim, meu arquivo possui cabeçalho"
                />
              </ContentContainer>
              <ContentContainer>
                <Radio
                  size="small"
                  name="hasntHeaderRadio"
                  checked={formik.values.hasntHeaderRadio}
                  value={formik.values.hasntHeaderRadio}
                  onChange={() => {
                    formik.setValues({
                      hasHeaderRadio: false,
                      hasntHeaderRadio: true,
                      initialCell: formik.values.initialCell || '',
                    });
                  }}
                  error={
                    formik.touched.hasHeaderRadio &&
                    Boolean(formik.errors.hasHeaderRadio)
                  }
                />
                <StyledText
                  setColor="neutral50"
                  variant="body4"
                  children="Não, meu arquivo não possui cabeçalho"
                />
              </ContentContainer>
            </FlexContainer>
            {formik.touched.hasHeaderRadio &&
              Boolean(formik.errors.hasHeaderRadio) &&
              Boolean(formik.errors.hasntHeaderRadio) && (
                <HelperTextContainer>
                  <StyledIcon
                    size={15}
                    name="IconAlertCircle"
                    fill="none"
                    setColor="error50"
                  />
                  <ErrorText variant="body4">
                    Esse dado deve ser indicado.
                  </ErrorText>
                </HelperTextContainer>
              )}
            <LinkButton
              variant="default"
              style={{ marginTop: '32px' }}
              onClick={() => setOpenHeaderModal(true)}
            >
              Como identificar se o arquivo possui cabeçalho?
              <StyledIcon
                name="IconExternalLink"
                fill="none"
                style={{ marginLeft: '5px' }}
              />
            </LinkButton>
          </Card>

          <Card>
            <TextContainer>
              <StyledTitle
                setColor="neutral30"
                variant="body1"
                children="Início dos dados"
              />
              <StyledText
                setColor="neutral50"
                variant="body4"
                children="Defina a célula onde se iniciam os dados dos usuários ou o próprio cabeçalho (se houver)."
              />
            </TextContainer>

            <HeaderContainer>
              <LeftTable isHeader={true}>
                <StyledTitle
                  setColor="neutral30"
                  variant="body3"
                  children="Defina a célula inicial dos dados"
                />
              </LeftTable>
              <RightTable>
                <StyledTitle
                  setColor="neutral30"
                  variant="body3"
                  children="Conteúdo da célula selecionada"
                />
              </RightTable>
            </HeaderContainer>
            <BodyContainer>
              <LeftTable isHeader={false}>
                <InputContainer>
                  <div>
                    <StyledTextField
                      label="Indique a Célula"
                      name="initialCell"
                      value={formik.values.initialCell}
                      onChange={(e: any) => {
                        const value = e.target.value
                          .replace(/[^a-z0-9]/gi, '')
                          .trim()
                          .toUpperCase();

                        formik.handleChange({
                          target: { id: 'initialCell', value: value },
                        });
                      }}
                      error={
                        (formik.touched.initialCell &&
                          Boolean(formik.errors.initialCell)) ||
                        emptyCell
                      }
                    />
                    {formik.touched.initialCell &&
                      Boolean(formik.errors.initialCell) && (
                        <HelperTextContainer>
                          <StyledIcon
                            size={15}
                            name="IconAlertCircle"
                            fill="none"
                            setColor="error50"
                          />
                          <ErrorText variant="body4">
                            {formik.errors.initialCell}
                          </ErrorText>
                        </HelperTextContainer>
                      )}
                  </div>
                </InputContainer>
                {emptyCell && (
                  <HelperTextContainer>
                    <StyledIcon
                      size={15}
                      name="IconAlertCircle"
                      fill="none"
                      setColor="error50"
                    />
                    <ErrorText variant="body4">
                      Esta célula não possui conteúdo ou não existe em seu
                      arquivo.
                    </ErrorText>
                  </HelperTextContainer>
                )}
              </LeftTable>

              <RightTable>
                <StyledText
                  setColor="neutral50"
                  variant="body4"
                  children={handleCellValue}
                />
              </RightTable>
            </BodyContainer>

            <LinkButton
              variant="default"
              onClick={() => {
                setOpenCellModal(true);
              }}
            >
              Como identificar a célula inícial dos dados?
              <StyledIcon
                name="IconExternalLink"
                fill="none"
                style={{ marginLeft: '5px' }}
              />
            </LinkButton>
          </Card>
        </RightColumn>
      </PageContainer>
      <ButtonArea
        disabled={emptyCell || !handleCellValue}
        variant="default"
        onForward={() => formik.handleSubmit()}
        onBackwards={() =>
          navigate(
            getWorksheetUrl(operation, EmployeesWorksheetSteps.uploadFile),
          )
        }
        onCancel={() => navigate('/employees')}
      />

      <HeaderHelperModal
        setOpen={openHeaderModal}
        onClose={() => setOpenHeaderModal(false)}
      />
      <CellHelperModal
        setOpen={openCellModal}
        onClose={() => setOpenCellModal(false)}
      />
    </>
  );
};
