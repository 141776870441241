import {
  BigNumber,
  LinkButton,
  PillButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 24px;
`;

export const BoxesContainer = styled.div<{ showCards: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  gap: 24px;
  height: ${(props) => (props.showCards ? '100%' : '0')};
  overflow: hidden;
  @media screen and (min-width: 1240px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledBigNumber = styled(BigNumber)<{
  type: string;
  filterActive: boolean;
}>`
  display: flex;
  flex-direction: row;
  min-width: 300px;
  max-width: 33%;
  @media screen and (min-width: 1240px) {
    min-width: 20%;
    max-width: 33%;
    max-height: 190px;
  }
  margin-right: ${({ type }) =>
    type === 'PENDING_FIRST_ACCESS' ? '0px' : '12px'};
  border-color: ${({ filterActive }) => (filterActive ? '#FF9AC9' : '#EBE6E9')};
`;

export const StyledIconButton = styled(PillButton)`
  width: 24px;
  height: 24px;
  margin-left: 12px;
`;

export const StyledButton = styled(LinkButton)`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: start;
`;
