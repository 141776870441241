import { trpc } from '@/api/client';

type UseChangeEmployeeCompanyProps = {
  options?: {
    onSuccess?: () => void;
    onError?: () => void;
  };
};

export const useChangeEmployeeCompany = ({
  options,
}: UseChangeEmployeeCompanyProps) => {
  const { mutate, isLoading } = trpc.changeEmployeeCompany.useMutation({
    onSuccess: () => {
      if (options?.onSuccess) {
        options.onSuccess();
      }
    },
    onError: () => {
      if (options?.onError) {
        options.onError();
      }
    },
  });

  const changeEmployeeCompany = async (
    employeeId: string,
    companyId: string,
  ) => {
    return mutate({
      companyId,
      employeeId,
    });
  };

  return {
    changeEmployeeCompany,
    isLoading,
  };
};
