import styled from 'styled-components';
import { Button, Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ContentWrapper = styled.div`
  text-align: center;
  margin: 20px 48px 0 0;
  max-width: 421px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProfilePictureChange = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextsWrapper = styled.div``;
export const Title = styled(Typography)``;
export const Description = styled(Typography)``;

export const OldPic = styled.div`
  position: relative;
  right: -20px;
  opacity: 50%;
`;

export const NewPic = styled.div`
  position: relative;
  right: 20px;
`;

export const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding: 24px;
    }
  }
`;

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;

  padding: 16px 112px;

  gap: 113px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterCancelButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: flex-end;

  text-decoration: underline;

  text-underline-offset: 10px;

  color: var(--color-neutral-50);

  margin: 16px 0;

  gap: 4px;
`;

export const SubmitButton = styled(Button)``;
