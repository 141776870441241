import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useEffect, useState } from 'react';
import { trpc } from 'src/api/client';
import { useDebouncedValue } from '../../../../../hooks/useDebouncedValue';

const SEARCH_INPUT_DELAY = 700;
const SEARCH_EMPLOYEE_LIMIT = 300;

export const useSearchEmployee = () => {
  const { selectedCompany } = useSelectedCompany();
  const [search, setSearchParam] = useState('');
  const query = useDebouncedValue(search, SEARCH_INPUT_DELAY);

  const { mutate, data, isLoading } = trpc.getEmployeesPaginated.useMutation();

  useEffect(() => {
    mutate({
      pageSize: SEARCH_EMPLOYEE_LIMIT,
      page: 1,
      companyId: selectedCompany.id,
      searchQuery: query,
    });
  }, [query]);

  return {
    data,
    loading: isLoading,
    search: setSearchParam,
  };
};
