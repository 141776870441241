import {
  SelectField,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Title = styled(Typography)`
  color: var(--color-neutral-dark3);
`;

export const Subtitle = styled(Typography)`
  color: var(--color-neutral-50);
  margin-bottom: 24px;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FieldTextLabel = styled(Typography)`
  font-weight: 600;
  color: var(--color-neutral-dark3);
`;

export const FieldTextInput = styled(TextField)`
  width: 100%;
`;

export const FieldSelect = styled(SelectField)`
  width: 100%;
`;

export const FieldRow = styled.div`
  display: flex;
  margin-bottom: 22px;
  position: relative;
  flex-direction: column;
`;

export const BottomTip = styled(Typography)`
  text-align: left;
  color: var(--color-neutral-dark5);
  font-weight: 600;
  padding-left: 10px;
  margin-top: 5px;
`;

export const AdornmentButton = styled.button`
  display: flex;
  min-width: fit-content;
  align-items: center;
`;

export const RequiredSymbol = styled.span`
  color: var(--color-feedback-error-light2);
  margin: 0 3px;
`;

export const StyledFieldsContainer = styled.div`
  max-width: 600px;
`;
