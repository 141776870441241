import { useNavigate } from 'react-router-dom';
import {
  MigrationType,
  useMultipleMigrationContext,
} from '@/pages/PageMultipleMigration/context/MultipleMigration.context';

import {
  Button,
  GenerateXLSXFile,
  Icons,
  PageContainer,
  PillButton,
} from '@flash-tecnologia/hros-web-ui-v2';

import { Aside } from '../../Aside';
import {
  StyledButtonContainer,
  StyledCard,
  StyledDescription,
  StyledDescriptionContainer,
  StyledDownloadButtonContainer,
  StyledInfo,
  StyledLabelIcons,
  StyledStatusText,
  StyledTitle,
} from './styles';

import { StyledMainContainer, StyledTitle as StyledPageTitle } from '../styles';
import { useMemo } from 'react';

export const ResultStep = () => {
  const { selectedCompany, employeeIds, errors, migrationType } =
    useMultipleMigrationContext();

  const navigate = useNavigate();

  const successCount = useMemo(
    () => employeeIds.length - errors.length,
    [employeeIds, errors],
  );

  const eventType = useMemo(
    () =>
      migrationType === MigrationType.MIGRATE ? 'transferidas' : 'adicionadas',
    [migrationType],
  );

  return (
    <PageContainer>
      <StyledPageTitle variant="headline6">Movimentar pessoas</StyledPageTitle>
      <StyledMainContainer>
        <Aside
          title="Status da movimentação"
          description="Parabéns! A transferência de colaboradores entre as empresas foi concluída com sucesso. Agora você já pode visualizar o resultado final na sua lista de colaboradores. "
        />
        <StyledCard>
          {errors.length > 0 ? (
            <PillButton
              size="medium"
              variant="error"
              icon="IconAlertCircle"
              type="primary"
            />
          ) : (
            <PillButton
              size="medium"
              variant="success"
              icon="IconCheck"
              type="primary"
            />
          )}
          <StyledTitle variant="headline8">
            Processamento finalizado
          </StyledTitle>

          <StyledDescriptionContainer>
            <StyledLabelIcons name="IconUsers" size={16} />
            <StyledDescription variant="body4">
              {successCount} pessoas foram {eventType} transferidas para a
              empresa {selectedCompany?.name}.
            </StyledDescription>
          </StyledDescriptionContainer>

          {errors.length > 0 && (
            <>
              <StyledInfo variant="body3" weight={700}>
                Algumas pessoas não puderam ser {eventType}
                para empresa {selectedCompany?.name}.
              </StyledInfo>
              <StyledDescriptionContainer>
                <StyledLabelIcons name="IconUsers" size={16} />
                <StyledDescription variant="body4">
                  {errors.length} pessoas não puderam ser {eventType}.
                </StyledDescription>
              </StyledDescriptionContainer>

              <StyledStatusText variant="body3">
                Confira no arquivo abaixo os erros. Você deverá refazer a
                movimentação dessas pessoas, caso necessário.
              </StyledStatusText>

              <StyledDownloadButtonContainer>
                <GenerateXLSXFile
                  fileName="Lista-de-erros.txt"
                  icon="IconDownload"
                  docIconSize={38}
                  data={errors.map(({ documentNumber, reason }) => {
                    return `- CPF: ${documentNumber}: ${reason}`;
                  })}
                />
              </StyledDownloadButtonContainer>
            </>
          )}

          <StyledButtonContainer>
            <Button
              onClick={() => navigate('/employees')}
              variant="primary"
              size="large"
            >
              <Icons name="IconArrowLeft" /> Voltar para a tela de equipe
            </Button>
          </StyledButtonContainer>
        </StyledCard>
      </StyledMainContainer>
    </PageContainer>
  );
};
