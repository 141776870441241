import styled from 'styled-components';
import { Tag } from '@flash-tecnologia/hros-web-ui-v2';

const SelectContainer = styled.div`
  width: 100%;
  max-width: 640px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  min-width: 64px;
  height: 64px;

  background: ${({ theme }) => theme.colors.feedback.info[90]};
  border-radius: 200px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 380px) {
    padding: 20px;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 981px;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 380px) {
    padding: 20px;
  }
`;

const RadioOptionText = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioOptionTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

const RadioCard = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  max-height: 240px;
  padding: 16px 24px 16px 24px;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;
    gap: 0px;
  }
`;

const RadioCardRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const RadioCardLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StyledTag = styled(Tag)`
  padding: 4px 8px 4px 8px;
  gap: 4px;
  font-size: 10px;
  line-height: 12x;
  border-radius: 24px;
  height: 20px;
  margin-left: 4px;
`;

export {
  SelectContainer,
  IconContainer,
  TextContainer,
  OptionContainer,
  RadioCard,
  RadioOptionTitle,
  RadioOptionText,
  RadioCardRight,
  RadioCardLeft,
};
