import { useEffect, useRef, useState } from 'react';

type useRetryInput<Output, Input> = {
  query: (params: Input) => Promise<Output>;
  maxAttempts: number;
  interval: number;
  stopCondition: (data: Output) => boolean;
  onCompleted: (data: Output) => void;
};

export const useRetry = <Input, Output>({
  maxAttempts,
  interval,
  stopCondition,
  onCompleted,
  query,
}: useRetryInput<Output, Input>) => {
  const [attempts, setAttempts] = useState(0);
  const [params, setParams] = useState<Input | null>(null);
  const [data, setData] = useState<Output | null>();
  const [loading, setLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const clearAttempts = () => {
    setAttempts(0);
    intervalIdRef?.current && clearInterval(intervalIdRef.current);
    setLoading(false);
    setIsPolling(false);
  };

  const polling = async () => {
    if (attempts < maxAttempts && isPolling) {
      const response = await query(params as Input);
      setData(response);
      setAttempts((prevAttempt) => prevAttempt + 1);
    } else {
      if (attempts === maxAttempts && onCompleted) {
        onCompleted(data!);
      }
      clearAttempts();
    }
  };

  useEffect(() => {
    if (isPolling) {
      intervalIdRef.current = setInterval(polling, interval);
    }

    if (stopCondition(data!)) {
      onCompleted(data!);
      clearAttempts();
    }

    return () => clearInterval(intervalIdRef.current!);
  }, [attempts, isPolling]);

  const start = (params: Input) => {
    setData(null);
    setParams(params);
    setIsPolling(true);
    setLoading(true);
  };

  return {
    startPolling: start,
    data,
    loading,
  };
};
