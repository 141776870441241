import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: start;
`;

export const StyledAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: 14px;
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const StyledWarningLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
