import { DatePicker, TimePicker } from '@flash-tecnologia/hros-web-ui-v2';
import dayjs from 'dayjs';

import {
  CreateInviteContainer,
  PickerWrapper,
  RadioButton,
  RadioOptionText,
  RadioWrapper,
  StyledRadio,
  Subtitle,
  Title,
} from './styled';

import { CreateInviteInterface } from './types';

export const CreateInvite = ({
  title,
  subtitle,
  inviteDate,
  inviteTime,
  dateError,
  timeError,
  setDate,
  setTime,
  setInviteMode,
  scheduleInvite,
  disabled,
}: CreateInviteInterface) => {
  return (
    <CreateInviteContainer disabled={disabled}>
      <Title variant="body3">{title}</Title>
      {subtitle && <Subtitle variant="body4">{subtitle}</Subtitle>}
      <RadioWrapper>
        <RadioButton>
          <StyledRadio
            checked={!scheduleInvite}
            onChange={() => setInviteMode(false)}
          />
          <RadioOptionText variant="body3">Enviar imediamente</RadioOptionText>
        </RadioButton>
        <RadioButton>
          <StyledRadio
            disabled={disabled}
            checked={scheduleInvite}
            onChange={() => setInviteMode(true)}
          />
          <RadioOptionText variant="body3">Agendar Envio</RadioOptionText>
        </RadioButton>
      </RadioWrapper>
      {scheduleInvite && (
        <PickerWrapper>
          <DatePicker
            fromDate={dayjs().add(1, 'day').toDate()}
            label="Data de envio"
            onDateChange={(date) => setDate(date!)}
            error={!!!inviteDate && scheduleInvite && dateError}
            helperText={
              dateError && scheduleInvite
                ? 'Escolha uma data para o envio do convite.'
                : ''
            }
          />
          <TimePicker
            disabled={true}
            value={inviteTime as any}
            error={!!!inviteTime && scheduleInvite && timeError}
            label={'Hora de envio'}
            onChange={(time) => {
              setTime(time);
            }}
            style={{ flex: 1 }}
            helperText={
              timeError && scheduleInvite
                ? 'Escolha um horário para o envio do convite.'
                : ''
            }
          />
        </PickerWrapper>
      )}
    </CreateInviteContainer>
  );
};
