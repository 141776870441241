import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledTextFieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const StyledCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledCheckBoxLabel = styled(Typography)<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.neutral[30]};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  opacity: ${({ disabled }) => (disabled ? '.5' : 'inherit')};
`;
