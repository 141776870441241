import { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { AbortImportProcess } from '../../modals';
import { MainContainer, LeftColumn, RightColumn } from './styled';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { ImportStatus, ImportType } from '../../types';
import { worksheetComponentInfo } from '../../componentsText';
import { useGetImport } from '../../hooks';
import {
  ProcessingFeedback,
  SuccessFeedback,
  SuccessWithErrors,
  WaitingFeedback,
  AbortedFeedback,
  ErroredFeedback,
} from './components';

interface IImportStatusProps {
  operation: ImportType;
  navigate: NavigateFunction;
  newImportUrl?: string;
  redirectBackTo?: string;
}

export const ImportFeedback = ({
  operation,
  navigate,
  newImportUrl,
  redirectBackTo,
}: IImportStatusProps) => {
  const [openModal, setOpenModal] = useState(false);

  const { importProcess, importStatus, successRowsQt, failedRowsQt } =
    useGetImport({});

  let mainCard = <></>;

  switch (importStatus) {
    case ImportStatus.PROCESSING:
      mainCard = (
        <ProcessingFeedback
          navigate={navigate}
          importProcess={importProcess}
          importStatus={importStatus}
          operation={operation}
          setOpenAbortModal={setOpenModal}
          newImportUrl={newImportUrl}
          redirectBackTo={redirectBackTo}
        />
      );
      break;
    case ImportStatus.SUCCESS_WITH_ERRORS:
      mainCard = (
        <SuccessWithErrors
          navigate={navigate}
          operation={operation}
          importProcess={importProcess}
          importStatus={importStatus}
          successRowsQt={successRowsQt}
          failedRowsQt={failedRowsQt}
          newImportUrl={newImportUrl}
          redirectBackTo={redirectBackTo}
        />
      );
      break;
    case ImportStatus.ERRORED:
      mainCard = (
        <ErroredFeedback
          navigate={navigate}
          operation={operation}
          importProcess={importProcess}
          importStatus={importStatus}
          newImportUrl={newImportUrl}
          redirectBackTo={redirectBackTo}
        />
      );
      break;
    case ImportStatus.SUCCESS:
      mainCard = (
        <SuccessFeedback
          navigate={navigate}
          importProcess={importProcess}
          importStatus={importStatus}
          operation={operation}
          successRowsQt={successRowsQt}
          newImportUrl={newImportUrl}
          redirectBackTo={redirectBackTo}
        />
      );
      break;
    case ImportStatus.ABORTED:
      mainCard = (
        <AbortedFeedback
          navigate={navigate}
          importStatus={importStatus}
          operation={operation}
          successRowsQt={successRowsQt}
          failedRowsQt={failedRowsQt}
          newImportUrl={newImportUrl}
          redirectBackTo={redirectBackTo}
        />
      );
      break;
    case ImportStatus.WAITING:
    default:
      mainCard = <WaitingFeedback operation={operation} />;
  }

  return (
    <PageContainer>
      <MainContainer>
        <LeftColumn>
          <StyledTitle
            setColor="secondary50"
            variant="body1"
            children={`${worksheetComponentInfo[operation].processmentStatusPage.mainContainer.title}`}
          />
          <StyledText
            setColor="neutral50"
            variant="body3"
            children={`${worksheetComponentInfo[operation].processmentStatusPage.mainContainer.description}`}
          />
        </LeftColumn>
        <RightColumn>{mainCard}</RightColumn>
        <AbortImportProcess
          navigate={navigate}
          operation={operation}
          setOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onInterrupt={() => {}}
          importId={importProcess?.importId!}
        />
      </MainContainer>
    </PageContainer>
  );
};
