import { useCallback, useMemo, useState } from 'react';

import { ITableGrid } from './types';

import {
  Checkbox,
  Icons,
  LinkButton,
  Skeleton,
  Table,
  tableControllers,
  Tag,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import {
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  StyledTableOptions,
} from './styled';
import { GenericProfilePicture } from '@Components/GenericProfilePicture';
import { EmployeeGroups } from './components/EmployeeGroups';
import { EmployeeOptions } from './components/EmployeeOptions';
import { StatusTag } from '@Components/StatusTag';
import { useSession } from '@/common/user';
import { useNavigate } from 'react-router-dom';
import {
  checkUnleashToggle,
  useUnleashToggles,
} from '@utils/checkUnleashToggle';

export const TableGrid = ({
  data,
  loading,
  totalCount,
  onPagination,
  pagination,
}: ITableGrid) => {
  const [selectedEmployees, setSelectedEmployees] = useState(new Set());
  const [allPageSelected, setAllPageSelected] = useState(false);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const { authUser, userId } = useSession();
  const navigate = useNavigate();

  const { economicGroupId } = useSession();

  const { flags } = useUnleashToggles({
    economicGroupId: economicGroupId,
  });

  const canUseMigrateEmployeeFeature = useMemo(
    () => checkUnleashToggle('FLASH_OS_ALLOW_EMPLOYEE_MIGRATION', flags),
    [flags],
  );

  const handleSingleSelect = useCallback((employeeId: string) => {
    setSelectedEmployees((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(employeeId)) {
        newSet.delete(employeeId);
      } else {
        newSet.add(employeeId);
      }
      return newSet;
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        header: () => (
          <>
            <Checkbox
              checked={allPageSelected}
              onChange={() => handleSelectAllPage()}
              aria-label="Select row"
            />
          </>
        ),
        id: 'select',
        accessorKey: 'id',
        size: 1,
        cell: ({ row }: { row: any }) => (
          <>
            <Checkbox
              checked={selectedEmployees.has(row?.original?.id)}
              onChange={() => handleSingleSelect(row?.original?.id)}
              aria-label="Select row"
            />
          </>
        ),
      },
      {
        header: 'Nome',
        id: 'name',
        accessorKey: 'name',
        size: 300,
        cell: (data: any) => {
          const email = data?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={data?.cell?.row?.original?.name}
                source={data?.cell?.row?.original?.profilePicture?.source}
              />
              <FirstColumnCellData>
                <Typography variant="body4">
                  {data?.cell?.row?.original?.name}
                </Typography>
                {!!email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        header: 'Status',
        id: 'status',
        accessorKey: 'status',
        accessorFn: () => null,
        cell: ({ row }: any) => <StatusTag status={row?.original?.status} />,
      },
      {
        header: 'CPF',
        id: 'documentNumber',
        accessorKey: 'documentNumber',
      },
      {
        header: 'Telefone',
        id: 'phoneNumber',
        accessorKey: 'phoneNumber',
      },
      {
        header: 'Grupo',
        id: 'groups',
        accessorKey: 'groups',
        cell: ({ row }: any) =>
          row?.original?.groups && (
            <EmployeeGroups groups={row?.original?.groups} />
          ),
      },
      {
        header: 'Ações',
        id: 'id',
        accessorKey: 'id',
        cell: (data: any) => (
          <EmployeeOptions
            userId={userId}
            employeeId={data?.cell?.row?.original?.id}
            status={data?.cell?.row?.original?.status}
            authUser={authUser}
            documentNumber={data?.cell.row?.original.documentNumber}
            enableMigration={canUseMigrateEmployeeFeature}
          />
        ),
        sticky: 'right',
      },
    ],
    [selectedEmployees, allPageSelected, allItemsSelected, data],
  );

  const table = tableControllers.useTableColumns({
    total: data?.length || 0,
    columns: columns as any,
    data: data || [],
    pagination: pagination,
    onPaginationChange: onPagination,
  });

  const handleSelectAllPage = useCallback(() => {
    setAllPageSelected((prev) => {
      const isSelectingAll = !prev;
      if (isSelectingAll && data?.length > 0) {
        const currentEmployeeIds = data.map((employee) => employee.id);
        currentEmployeeIds.forEach((id) => {
          selectedEmployees.add(id);
        });
      } else {
        selectedEmployees.clear();
      }

      return isSelectingAll;
    });
  }, [data, selectedEmployees]);

  const handleToggleSelectAllItems = useCallback(() => {
    setAllItemsSelected((prev) => {
      const isSelectingAll = !prev;
      if (isSelectingAll && data?.length > 0) {
        const currentEmployeeIds = data.map((employee) => employee.id);
        currentEmployeeIds.forEach((id) => selectedEmployees.add(id));
      } else {
        selectedEmployees.clear();
        setAllPageSelected(false);
      }

      return isSelectingAll;
    });
  }, [data, selectedEmployees]);

  const pageSizeOptions = [
    {
      label: '10 itens',
      value: 10,
    },
    {
      label: '25 itens',
      value: 25,
    },
    {
      label: '50 itens',
      value: 50,
    },
    {
      label: '100 itens',
      value: 100,
    },
    {
      label: '250 itens',
      value: 250,
    },
  ];

  const resetSelectors = useCallback(() => {
    setAllItemsSelected(false);
    setAllPageSelected(false);
    selectedEmployees.clear();
  }, []);

  const buildQueryParams = useCallback(() => {
    if (allItemsSelected) {
      return 'employeeIds=ALL';
    }

    const employeeIds = Array.from(selectedEmployees).join(',');
    return `employeeIds=${employeeIds}`;
  }, [allItemsSelected, selectedEmployees]);

  return (
    <>
      {loading ? (
        <Skeleton
          variant="rectangular"
          style={{
            marginBottom: '0px',
            borderRadius: '20px',
            height: '1000px',
            display: 'flex',
            flexGrow: 1,
          }}
        />
      ) : (
        <Table.Root>
          <Table.Grid.Root>
            {selectedEmployees.size > 0 || allPageSelected ? (
              <tr
                className="data-grid-table-header-bulk-actions-container"
                role="row"
              >
                <th className="data-grid-table-header-bulk-actions">
                  <Checkbox
                    onChange={() => handleSelectAllPage()}
                    indeterminate={allPageSelected}
                    checked={allPageSelected}
                    aria-label="column row checkbox"
                  />
                </th>
                <th
                  className="data-grid-table-header-bulk-actions"
                  style={{ paddingLeft: '0px' }}
                >
                  <StyledTableOptions>
                    <Tag as="span" size="small" variant="primary">
                      {allItemsSelected ? totalCount : selectedEmployees.size}{' '}
                      de {totalCount} selecionados
                    </Tag>
                    {allPageSelected && selectedEmployees.size < totalCount && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        {allItemsSelected && (
                          <Typography variant="body4">
                            Todos os {totalCount} itens foram selecionados.
                          </Typography>
                        )}
                        <LinkButton
                          variant="default"
                          onClick={() => handleToggleSelectAllItems()}
                        >
                          {allItemsSelected
                            ? 'Limpar seleção'
                            : `Selecionar todos os ${totalCount} itens`}
                        </LinkButton>
                      </div>
                    )}
                    {canUseMigrateEmployeeFeature && (
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `/employees/multiple-migration?${buildQueryParams()}`,
                          )
                        }
                        variant="neutral"
                      >
                        <Icons
                          style={{ marginRight: '4px' }}
                          size={18}
                          name="IconArrowsShuffle"
                          fill="transparent"
                        />
                        Movimentar pessoas
                      </LinkButton>
                    )}
                  </StyledTableOptions>
                </th>
              </tr>
            ) : (
              <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
            )}
            {table.rows.map((row) => (
              <Table.Grid.Row key={row.id} row={row} />
            ))}
          </Table.Grid.Root>
          <Table.Pagination
            pageSizeOptions={pageSizeOptions}
            pagination={pagination}
            count={totalCount}
            onPaginationChange={(value) => {
              onPagination(value);
              resetSelectors();
            }}
          />
        </Table.Root>
      )}
    </>
  );
};
