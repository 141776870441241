import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const StyledCard = styled.div`
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 40px;
`;

export const StyledIconContainer = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-top: 10px;
  margin-bottom: 2px;
`;

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin: 4px 0px;
  max-width: 50%;
`;

export const StyledInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  margin-top: 32px;
`;

export const StyledStatusText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledProgressContainer = styled.div`
  margin-top: 20px;
  width: 50%;
  & > progress::-webkit-progress-value {
    transition: width 2s ease-in-out;
  }
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 64px;
`;
