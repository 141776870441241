import dayjs from 'dayjs';
import { useState } from 'react';

export const useSuccessCreateEmployeeModal = () => {
  const [scheduleInvite, setScheduleInvite] = useState<{
    isScheduleInvite: boolean;
    date: dayjs.Dayjs | null;
  }>({
    isScheduleInvite: false,
    date: null,
  });

  const [config, setConfig] = useState({
    isOpen: false,
    title: '',
    description: '',
    showInvitationCode: false,
  });

  const openSuccessCreateEmployeeModal = () => {
    if (scheduleInvite.isScheduleInvite) {
      return setConfig({
        isOpen: true,
        title: 'Convite agendado com sucesso!',
        description: `O convite de primeiro acesso será enviado em ${scheduleInvite.date?.format(
          'DD/MM/YYYY',
        )}.`,
        showInvitationCode: false,
      });
    }

    return setConfig({
      isOpen: true,
      title: 'Pessoa cadastrada com sucesso!',
      description: `Você também pode copiar o link de primeiro acesso e enviar diretamente para a pessoa:`,
      showInvitationCode: true,
    });
  };

  const closeSuccessCreateEmployeeModal = () => {
    setConfig({
      ...config,
      isOpen: false,
    });
  };

  return {
    successCreateEmployeeModal: {
      open: openSuccessCreateEmployeeModal,
      close: closeSuccessCreateEmployeeModal,
      config,
    },
    setScheduleInvite,
    isScheduleInvite: scheduleInvite.isScheduleInvite,
  };
};
