import { Radio, PillButton } from '@flash-tecnologia/hros-web-ui-v2';

import {
  TextContainer,
  RadioCard,
  RadioOptionText,
  OptionContainer,
  RadioCardRight,
  RadioCardLeft,
} from '../styled';

import { StyledText, StyledTitle } from 'src/common/styles/general-styles';

interface IReactivateOptions {
  formik: any;
}

export const ReactivateOptions = ({ formik }: IReactivateOptions) => {
  return (
    <OptionContainer>
      <TextContainer>
        <StyledTitle
          setColor="neutral30"
          variant="body1"
          children="O que fazer com cadastros inativos"
        />
        <StyledText
          setColor="neutral50"
          variant="body4"
          children="Defina o que fazer se uma ou mais pessoas da planilha estiverem desativadas dentro do grupo econômico."
        />
      </TextContainer>
      <RadioCard>
        <RadioCardLeft>
          <Radio
            size="small"
            name="notReactivate"
            checked={formik.values.notReactivate}
            value={formik.values.notReactivate}
            onChange={() => {
              formik.setFieldValue('notReactivate', true);
              formik.setFieldValue('reactivate', false);
            }}
            error={
              formik.touched.notReactivate &&
              Boolean(formik.errors.notReactivate)
            }
          />
          <RadioOptionText>
            <StyledTitle
              setColor="neutral30"
              variant="body3"
              children="Não fazer nada"
            />
            <StyledText
              setColor="neutral50"
              variant="body4"
              children="Manter o cadastro das pessoas inativo."
            />
          </RadioOptionText>
        </RadioCardLeft>
        <RadioCardRight>
          <PillButton
            variant="pink"
            size="medium"
            icon="IconWashDrycleanOff"
            type="primary"
          />
        </RadioCardRight>
      </RadioCard>
      <RadioCard>
        <RadioCardLeft>
          <Radio
            size="small"
            name="reactivate"
            checked={formik.values.reactivate}
            value={formik.values.reactivate}
            onChange={() => {
              formik.setFieldValue('reactivate', true);
              formik.setFieldValue('notReactivate', false);
            }}
            error={
              formik.touched.reactivate && Boolean(formik.errors.reactivate)
            }
          />
          <RadioOptionText>
            <StyledTitle
              setColor="neutral30"
              variant="body3"
              children="Reativar o cadastro das pessoas"
            />

            <StyledText
              setColor="neutral50"
              variant="body4"
              children={
                <>
                  <>
                    Essas pessoas terão o cadastro reativado e permanecerão
                    vinculadas às empresas onde estão atualmente.
                  </>
                  <b>
                    {' '}
                    A reativação trará de volta os dados e informações conforme
                    registrados à época da desativação; para editar esses dados,
                    é necessário atualizar os dados via fluxo de edição de
                    informações da pessoa.
                  </b>
                </>
              }
            />
          </RadioOptionText>
        </RadioCardLeft>
        <RadioCardRight>
          <PillButton
            variant="pink"
            size="medium"
            icon="IconUserExclamation"
            type="primary"
          />
        </RadioCardRight>
      </RadioCard>
    </OptionContainer>
  );
};
