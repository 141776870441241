import styled from 'styled-components';
import { Button, Icons, Modal } from '@flash-tecnologia/hros-web-ui-v2';

const StyledModal = styled(Modal.Root)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 480px;
      max-height: 95vh;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 0px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  padding: 40px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  gap: 8px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 400px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  gap: 120px;
  padding: 20px;

  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

const StyledButton = styled(Button)`
  && {
    width: 226px;

    &.button-custom-theme-primary.MuiPaper-root.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.feedback.error[90]};
      color: ${({ theme }) => theme.colors.feedback.error[40]};

      &.MuiPaper-root.MuiButtonBase-root {
        :hover {
          box-shadow: 0px 0px 0px white;
          background-color: ${({ theme }) => theme.colors.feedback.error[90]};
        }
      }
    }
  }
`;

const SubtitleBold = styled.span`
  font-weight: 700;
  font-size: 14px;
  font-style: bold;
  line-height: 22px;
  color: var(--color-neutral-50);
`;

const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error[40]}};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  min-width: 48px;
  height: 48px;

  background-color: ${({ theme }) => theme.colors.feedback.error[90]}};;
  border-radius: 200px;

`;

export {
  HeaderContainer,
  StyledModal,
  SubtitleBold,
  MainContainer,
  TextContainer,
  FooterContainer,
  StyledButton,
  StyledIcon,
  IconContainer,
};
