import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { keyframes } from 'styled-components';

export const StyledRequiredSymbol = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[50]};
`;

export const StyledRequiredContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFieldWrapper = styled.div`
  width: 100%;
`;

export const StyledRequiredLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding-bottom: 24px;
`;

export const StyledLabelContainer = styled.div`
  display: flex;
`;

export const StyledLabel = styled(Typography)`
  margin: 6px 0 0 16px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

const slideUp = keyframes`
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const StyledAdditionalFieldsContainer = styled.div`
  animation: ${slideUp} 1s ease-out forwards;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledLinkContainer = styled.div`
  display: flex;
`;

export const StyledLinkIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  margin-right: 5px;
`;

export const StyledSpinnerContainer = styled.div`
  margin-right: 5px;
`;
