import {
  Button,
  Icons,
  LinkButton,
  Spinner,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StyledFooterContainer } from './styles';
import { FormTypeEnum, useForm } from '../../context/FormContext';
import { useMemo, useState } from 'react';
import { useFindEmployeeContacts } from '../../hooks/useFindEmployeeContacts';
import { ModalWarning } from '../ModalWarning';
import { useCreateEmployee } from '@/hooks/useCreateEmployee';
import dispatchToast from '@utils/dispatchToast';
import { useLastInvitation } from '../../hooks/useLastInvitation';
import { useRetry } from '../../hooks/useRetry';

const modalValues = {
  phone: {
    title: 'Já existe uma pessoa cadastrada com esse celular!',
    description:
      'O convite de primeiro acesso será enviado para o celular informado. Certifique-se de que a pessoa correta conseguirá acessá-lo para criar seu cadastro no app e plataforma da Flash.',
  },
  email: {
    title: 'Já existe uma pessoa cadastrada com esse e-mail!',
    description:
      'O convite de primeiro acesso será enviado para o e-mail informado. Certifique-se de que a pessoa correta conseguirá acessá-lo para criar seu cadastro no app e plataforma da Flash.',
  },
  both: {
    title: 'Já existe uma pessoa cadastrada com esse e-mail e celular!',
    description:
      'O convite de primeiro acesso será enviado para o e-mail informado. Certifique-se de que a pessoa correta conseguirá acessá-lo para criar seu cadastro no app e plataforma da Flash. ',
  },
};

type FooterProps = {
  onClose: () => void;
  onCreateInviteCode: (code: string) => void;
};

export const Footer = ({ onClose, onCreateInviteCode }: FooterProps) => {
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [contactExists, setContactExists] = useState<
    'email' | 'phone' | 'both'
  >('email');
  const {
    errors,
    allFieldsDisabled,
    validateFields,
    form,
    invitationType,
    formType,
    disableEmailField,
  } = useForm();

  const { createEmployee, isLoading: createEmployeeLoading } =
    useCreateEmployee({
      options: {
        onError: () => {
          dispatchToast({
            type: 'error',
            title: 'Erro ao cadastrar pessoa!',
            content: `Por favor, tente novamente.`,
          });
        },
        onSuccess: (employee) => {
          if (invitationType === 'SCHEDULED' || disableEmailField) {
            dispatchToast({
              type: 'success',
              title: 'Pessoa cadastrada com sucesso!',
              content: `O cadastro da pessoa foi realizado com sucesso. Agora ela está disponível no sistema e pronta para futuras operações.`,
            });

            onClose();
            return;
          }

          startPolling({ employeeId: employee.id });
        },
      },
    });

  const { getLastInvitation } = useLastInvitation();
  const { startPolling, loading: findInviteLoading } = useRetry({
    maxAttempts: 5,
    interval: 4000,
    stopCondition: (data) => {
      return !!data;
    },
    query: getLastInvitation,
    onCompleted: (data) => {
      onCreateInviteCode(data);
      onClose();
    },
  });

  const loading = useMemo(
    () => createEmployeeLoading || findInviteLoading,
    [createEmployeeLoading, findInviteLoading],
  );

  const handleSubmit = async () => {
    const isValidInput = validateFields();

    if (!isValidInput) {
      return;
    }

    const employee = {
      name: form.name,
      email: form.email,
      phone: form.phone,
      documentNumber: form.documentNumber,
      invitationDate: form.invitationDate,
    };

    const payload = formType === FormTypeEnum.SIMPLE ? employee : form;

    await createEmployee(payload);
  };

  const { findEmployeeContacts } = useFindEmployeeContacts({
    onFound: (foundContact) => {
      const isValidInput = validateFields();

      if (!isValidInput) {
        return;
      }

      setContactExists(foundContact);
      setOpenModalWarning(true);
    },
    onNotFound: async () => {
      await handleSubmit();
    },
  });

  const hasErrors = Object.values(errors).filter((error) => !!error);

  const disableButton = useMemo(
    () => hasErrors.length > 0 || allFieldsDisabled || loading,
    [hasErrors, allFieldsDisabled],
  );

  return (
    <StyledFooterContainer>
      <LinkButton onClick={() => onClose()} variant="neutral">
        Cancelar
      </LinkButton>
      <Button
        onClick={() => {
          findEmployeeContacts(form.phone, form.email);
        }}
        disabled={disableButton}
        loading={loading}
        variant="primary"
      >
        {loading ? 'Enviando...' : 'Enviar'}
        {!loading && <Icons name="IconArrowRight" />}
      </Button>
      <ModalWarning
        isOpen={openModalWarning}
        onClose={() => setOpenModalWarning(false)}
        onSubmit={() => {
          handleSubmit();
          setOpenModalWarning(false);
        }}
        title={modalValues[contactExists].title}
        description={modalValues[contactExists].description}
      />
    </StyledFooterContainer>
  );
};
