import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledTitle = styled(Typography)`
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledMainContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 40px;
`;
