import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BluebirdPromise from 'bluebird';
import {
  MigrationStepEnum,
  MigrationType,
  useMultipleMigrationContext,
} from '@/pages/PageMultipleMigration/context/MultipleMigration.context';

import {
  Button,
  Icons,
  PageContainer,
  PillButton,
  ProgressBar,
} from '@flash-tecnologia/hros-web-ui-v2';

import { Aside } from '../../Aside';
import {
  StyledButtonContainer,
  StyledCard,
  StyledDescription,
  StyledIconContainer,
  StyledInfo,
  StyledProgressContainer,
  StyledStatusText,
  StyledTitle,
} from './styles';

import { StyledMainContainer, StyledTitle as StyledPageTitle } from '../styles';
import { useCreateMigrationFlow } from './hooks/useCreateMigrationFlow';
import { useCreateDuplicationFlow } from './hooks/useCreateDuplicationFlow';

const DELAY = 5000;

BluebirdPromise.config({ cancellation: true });

export const ProcessingStep = () => {
  const { selectedCompany, employeeIds, setErrors, setStep, migrationType } =
    useMultipleMigrationContext();
  const [completedCalls, setCompletedCalls] = useState(0);
  const migrationPromiseRef = useRef<undefined | BluebirdPromise<any>>(
    undefined,
  );
  const errorsRef = useRef<{ documentNumber: string; reason: string }[]>([]);
  const navigate = useNavigate();
  const { createMigrationFlow } = useCreateMigrationFlow({
    company: selectedCompany!,
    updateCalls: setCompletedCalls,
    errorsRef: errorsRef,
  });

  const { createDuplicationFlow } = useCreateDuplicationFlow({
    company: selectedCompany!,
    updateCalls: setCompletedCalls,
    errorsRef: errorsRef,
  });

  const progress = Math.trunc((completedCalls / employeeIds.length) * 100);

  useEffect(() => {
    migrationPromiseRef.current = BluebirdPromise.map(
      employeeIds,
      (employeeId) =>
        BluebirdPromise.delay(DELAY).then(() =>
          migrationType === MigrationType.MIGRATE
            ? createMigrationFlow(employeeId)
            : createDuplicationFlow(employeeId),
        ),
      { concurrency: 10 },
    ).then(() => {
      setErrors(errorsRef.current);
      setStep(MigrationStepEnum.RESULT);
    });
  }, []);

  const cancelMigration = () => {
    if (migrationPromiseRef.current) migrationPromiseRef.current.cancel();
  };

  useEffect(() => cancelMigration, []);

  const eventType = useMemo(
    () =>
      migrationType === MigrationType.MIGRATE ? 'Transferindo' : 'Adicionando',
    [migrationType],
  );

  return (
    <PageContainer>
      <StyledPageTitle variant="headline6">Movimentar pessoas</StyledPageTitle>
      <StyledMainContainer>
        <Aside
          title="Status da movimentação"
          description="Acompanhe aqui o status da sua transferência. Esse processo pode demorar alguns minutos. Atenção: Não feche esta janela até que o processo de transferência seja finalizado."
        />
        <StyledCard>
          <StyledIconContainer>
            <PillButton
              size="medium"
              variant="pink"
              icon="IconRefresh"
              type="primary"
            />
          </StyledIconContainer>
          <StyledTitle variant="headline8">Em processamento</StyledTitle>
          <StyledDescription variant="body4">
            Assim que o processamento for finalizado, notificaremos aqui na
            plataforma o resultado da movimentação.
          </StyledDescription>

          <StyledInfo variant="body3" weight={700}>
            {eventType} pessoas para a empresa {selectedCompany?.name}.
          </StyledInfo>
          <StyledStatusText variant="body3">
            {completedCalls} de {employeeIds.length} pessoas transferidas
          </StyledStatusText>
          <StyledProgressContainer>
            <ProgressBar variant="default" progress={progress} />
          </StyledProgressContainer>
          <StyledButtonContainer>
            <Button onClick={cancelMigration} variant="secondary" size="large">
              <Icons name="IconX" /> Interromper transferência
            </Button>
            <Button
              onClick={() => navigate('/employees')}
              variant="primary"
              size="large"
            >
              <Icons name="IconArrowLeft" /> Voltar para a tela de equipe
            </Button>
          </StyledButtonContainer>
        </StyledCard>
      </StyledMainContainer>
    </PageContainer>
  );
};
