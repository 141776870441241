import { CUSTOM_ACCESS_MENU_QUERY } from '@/api/queries/custom-access-menu-query';
import { useSession } from '@/common/user';
import { useQuery } from '@apollo/client';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import { useEffect } from 'react';
import { trpc } from '@/api/client';

export const useFetchCompanySpecialRedemptionCode = () => {
  const { companyId } = useSession();

  const { data: specialRedemptionCode, isLoading } =
    trpc.getCompanySpecialRedemptionCode.useQuery({
      companyId,
    });

  return {
    specialRedemptionCode,
    isLoading,
  };
};
