import React, { ComponentType, ReactNode } from 'react';
import { StyledLabelContainer } from './styles';

type WithLabelProps = {
  additionalLabel?: ReactNode;
};

function withAdditionalLabel<P extends object>(
  WrappedComponent: ComponentType<P>,
) {
  const WithLabel: React.FC<Omit<P, 'additionalLabel'> & WithLabelProps> = ({
    additionalLabel,
    ...props
  }) => (
    <StyledLabelContainer>
      <WrappedComponent {...(props as P)} />
      {additionalLabel}
    </StyledLabelContainer>
  );

  return WithLabel;
}

export default withAdditionalLabel;
