import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledNotificationCard = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.secondary[80]};
  padding: 24px;
  gap: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 90%;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledCloseIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;
