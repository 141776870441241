import { Radio } from '@mui/material';
import {
  StyledRadioContainer,
  StyledRadioDescription,
  StyledRadioText,
  StyledRadioTitle,
} from './styles';

type RadioOptionProps = {
  title: string;
  description: string;
  value: string;
  checked?: boolean;
  onChange: () => void;
  disabled?: boolean;
};

export const RadioOption = ({
  title,
  description,
  value,
  checked,
  onChange,
  disabled,
}: RadioOptionProps) => {
  const handleChange = () => {
    if (disabled) {
      return;
    }

    onChange();
  };

  return (
    <StyledRadioContainer
      disabled={disabled}
      onClick={() => handleChange()}
      checked={!!checked}
    >
      <Radio
        size="small"
        name="simpleImport"
        checked={checked}
        value={value}
        disabled={disabled}
      />
      <StyledRadioText>
        <StyledRadioTitle variant="body3" weight={600}>
          {title}
        </StyledRadioTitle>
        <StyledRadioDescription variant="body4" weight={400}>
          {description}
        </StyledRadioDescription>
      </StyledRadioText>
    </StyledRadioContainer>
  );
};
