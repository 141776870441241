import { useMemo, useState } from 'react';

import { CancelScheduleModal } from './Modals/CancelSchedule';
import { EditScheduleModal } from './Modals/EditSchedule';

import {
  ButtonsArea,
  IconContainer,
  MainContainer,
  StyledIcon,
  TextContainer,
  StyledText,
  StyledTitle,
  StyledLinkButton,
} from './styles';
import dayjs from 'dayjs';
import { Countdown } from './Countdown';
import { StatusEnum } from 'src/common/types/StatusEnum';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { useResendInvite } from './hooks/useResendInvite';
import { useSendInvite } from './hooks/useSendInvite';

export const InviteCard = ({
  status,
  invitationDate,
  name,
  employeeId,
}: {
  status: StatusEnum;
  invitationDate?: Date;
  name: string;
  employeeId: string;
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const { resendInvite, resetTimer, timer } = useResendInvite(employeeId);
  const { sendInvite } = useSendInvite(resetTimer);

  const formattedDate = invitationDate
    ? dayjs(invitationDate).format('DD/MM/YYYY [às] HH:mm')
    : undefined;

  const renderData: {
    title: string;
    icon: IconTypes;
    description: string;
    buttons: {
      text: string;
      onClick: () => void;
    }[];
    shouldShowTimer?: boolean;
  } = useMemo(() => {
    switch (status) {
      case StatusEnum.INVITATION_SCHEDULED:
        return {
          icon: 'IconCircleCheck',
          title: 'Convite de boas-vindas agendado',
          description: `O convite para primeiro acesso à plataforma foi agendado para o
          dia ${formattedDate || ''}`,
          buttons: [
            {
              text: 'Editar envio',
              onClick: () => {
                setOpenEditModal(true);
              },
            },
            {
              text: 'Cancelar envio',
              onClick: () => setOpenCancelModal(true),
            },
          ],
        };
      case StatusEnum.INVITATION_SENT:
        return {
          icon: 'IconAlertCircle',
          title: 'Convite de boas-vindas enviado',
          description: 'O convite de primeiro acesso à plataforma foi enviado.',
          buttons: [
            {
              text: 'Reenviar',
              onClick: () => sendInvite({ variables: { employeeId } }),
            },
          ],
          shouldShowTimer: true,
        };
      case StatusEnum.INVITATION_EXPIRED:
        return {
          icon: 'IconCircleX',
          title: 'Convite de boas-vindas expirado',
          description: `Reenvie o convite para que a pessoa realize o cadastro e
          primeiro acesso na plataforma.`,
          buttons: [
            {
              text: 'Reenviar',
              onClick: () => sendInvite({ variables: { employeeId } }),
            },
          ],
          shouldShowTimer: true,
        };
      case StatusEnum.INVITATION_PENDING:
        return {
          icon: 'IconAlertCircle',
          title: 'Convite de boas-vindas cancelado',
          description:
            'Envie o convite para que a pessoa realize o cadastro e primeiro acesso na plataforma.',
          buttons: [
            {
              text: 'Envio do convite',
              onClick: () => {
                setOpenEditModal(true);
              },
            },
          ],
        };
      default:
        return {
          title: '',
          icon: 'IconCircleX',
          description: '',
          buttons: [],
        };
    }
  }, [status, formattedDate, timer]);

  return (
    <div style={{ marginBottom: '25px' }}>
      <MainContainer status={status}>
        <IconContainer status={status}>
          <StyledIcon
            status={status}
            name={renderData.icon}
            fill="none"
            size={30}
            stroke="1.7px"
          />
        </IconContainer>
        <TextContainer>
          <StyledTitle variant="body2">{renderData.title}</StyledTitle>
          <StyledText variant="body4">{renderData.description}</StyledText>
          <ButtonsArea>
            {renderData.buttons.map((button, index) => (
              <StyledLinkButton
                key={index}
                variant="default"
                onClick={button.onClick}
              >
                {button.text}
              </StyledLinkButton>
            ))}
            {renderData.shouldShowTimer && timer > 0 && (
              <Countdown time={timer} />
            )}
          </ButtonsArea>
        </TextContainer>
      </MainContainer>
      <EditScheduleModal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        name={name}
        resetTimer={resetTimer}
      />
      <CancelScheduleModal
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        employeeId={employeeId}
      />
    </div>
  );
};
