import {
  PageContainer,
  Tag,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useEffect } from 'react';
import {
  StyledForms,
  StyledOptionsWrapper,
  StyledOptionTitle,
  StyledPageContainer,
} from './styles';
import { Aside } from '../../Aside';
import { FormGroup } from './components/FormGroup';
import { FormOption } from './components/FormOption';
import { SelectCompanyField } from './components/SelectCompanyField';
import { Footer } from './components/Footer';
import { NotificationCard } from './components/NotificationCard';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MigrationType,
  useMultipleMigrationContext,
} from '@/pages/PageMultipleMigration/context/MultipleMigration.context';
import { StyledMainContainer, StyledTitle } from '../styles';
import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const FormStep = () => {
  const { setMigrationType, migrationType, setEmployeeIds } =
    useMultipleMigrationContext();
  const { selectedCompany } = useSelectedCompany();
  const { mutateAsync, isLoading } = trpc.getAllEmployees.useMutation();

  const location = useLocation();
  const navigate = useNavigate();

  const getAllEmployees = async () => {
    const { employees } = await mutateAsync({ companyId: selectedCompany.id });
    return employees;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const [ids] = searchParams.getAll('employeeIds');

    const fetchEmployees = async () => {
      if (!ids) {
        return;
      }

      let employeeIds: string[] = [];

      if (ids === 'ALL') {
        const employees = await getAllEmployees();
        employeeIds = employees.map((employee) => employee.id);
      } else {
        employeeIds = ids.split(',');
      }

      setEmployeeIds(employeeIds);
      searchParams.delete('employeeIds');

      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    };

    fetchEmployees();
  }, [location, navigate]);

  return (
    <StyledPageContainer>
      <PageContainer>
        <StyledTitle variant="headline6">Movimentar pessoas</StyledTitle>
        <StyledMainContainer>
          <Aside
            title="Transfira ou adicione pessoas às empresas do grupo"
            description="Gerencie a associação de usuários entre empresas. Escolha entre transferir as pessoas de uma empresa para outra ou adicionar as pesoas a uma nova empresa, mantendo-o ativo em ambas."
          />
          <StyledForms>
            {!isLoading && <NotificationCard />}
            <FormGroup title="Tipo de movimentação" required>
              <StyledOptionsWrapper>
                <FormOption
                  title="Transferir essas pessoas para outra empresa"
                  description={
                    <>
                      Ao transferir essas pessoas para outra empresa do grupo,
                      elas deixarão de estar vinculadas à empresa atual e
                      passarão a estar vinculadas{' '}
                      <Typography tag="span" variant="body4" weight={700}>
                        apenas à nova empresa.
                      </Typography>{' '}
                    </>
                  }
                  icon="IconSwitch3"
                  active={migrationType === MigrationType.MIGRATE}
                  onClick={() => setMigrationType(MigrationType.MIGRATE)}
                />
                <FormOption
                  title={
                    <StyledOptionTitle>
                      Duplicar o cadastro dessas pessoas em outra empresa{' '}
                      <Tag as="span" size="small" variant="yellow">
                        Não recomendado
                      </Tag>
                    </StyledOptionTitle>
                  }
                  description={
                    <>
                      Ao duplicar o cadastro dessas pessoas para outra empresa,
                      elas{' '}
                      <Typography tag="span" variant="body4" weight={700}>
                        ficarão vinculadas a mais uma empresa
                      </Typography>
                      , além das quais já estão vinculadas.{' '}
                      <Typography tag="span" variant="body4" weight={700}>
                        No entanto, esta ação não é recomendada, pois gera
                        cadastros duplicados
                      </Typography>
                      , o que pode resultar na emissão de mais um cartão Flash e
                      outros problemas relacionados à duplicidade de cadastro.
                    </>
                  }
                  icon="IconLayoutGridAdd"
                  active={migrationType === MigrationType.DUPLICATE}
                  onClick={() => setMigrationType(MigrationType.DUPLICATE)}
                />
              </StyledOptionsWrapper>
            </FormGroup>
            <FormGroup
              title="Selecione as empresas de destino"
              description="Escolha em quais empresas do grupo essas pessoas deverão ser adicionadas."
            >
              <SelectCompanyField />
            </FormGroup>
          </StyledForms>
        </StyledMainContainer>
      </PageContainer>
      <Footer />
    </StyledPageContainer>
  );
};
