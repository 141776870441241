import { Radio, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styled';
import { BatchActionTypes } from '../../BatchActionTypes';
import dayjs, { Dayjs } from 'dayjs';

interface FormProps {
  selectedAction: string;
  selectedDate: Dayjs;
  onChange: (action: BatchActionTypes) => void;
  setSelectedDate: (date: Dayjs) => void;
}

export const Form = ({
  selectedAction,
  selectedDate,
  onChange,
  setSelectedDate,
}: FormProps) => {
  return (
    <SC.FormContainer>
      <SC.Title variant="body3">
        Selecione entre envio imediato ou escolha uma data específica
      </SC.Title>

      <SC.Row gap="12px">
        <SC.Row>
          <Radio
            checked={selectedAction === BatchActionTypes.RESEND_INVITES}
            value={BatchActionTypes.RESEND_INVITES}
            onChange={() => onChange(BatchActionTypes.RESEND_INVITES)}
            id={BatchActionTypes.RESEND_INVITES}
          />
          <label htmlFor={BatchActionTypes.RESEND_INVITES}>
            <Typography variant="body3">Enviar imediamente</Typography>
          </label>
        </SC.Row>

        <SC.Row>
          <Radio
            checked={selectedAction === BatchActionTypes.RESCHEDULE_INVITES}
            value={BatchActionTypes.RESCHEDULE_INVITES}
            onChange={() => onChange(BatchActionTypes.RESCHEDULE_INVITES)}
            id={BatchActionTypes.RESCHEDULE_INVITES}
          />
          <label htmlFor={BatchActionTypes.RESCHEDULE_INVITES}>
            <Typography variant="body3">Agendar envio</Typography>
          </label>
        </SC.Row>
      </SC.Row>
      {selectedAction === BatchActionTypes.RESCHEDULE_INVITES && (
        <SC.Row gap="16px">
          <SC.StyledDatePicker
            fromDate={dayjs().add(1, 'day').toDate()}
            label={'Data de envio'}
            value={selectedDate?.toDate()}
            name={'date'}
            onDateChange={(date) => {
              if (date) setSelectedDate(date.add(8, 'hours'));
            }}
          />
          <SC.StyledTimePicker
            label={'Hora de envio'}
            value={dayjs().hour(8).minute(0).second(0) as any}
            onChange={() => {}}
            disabled
          />
        </SC.Row>
      )}
    </SC.FormContainer>
  );
};
