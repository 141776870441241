import { DataGrid, Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledDataGrid = styled(DataGrid)``;

export const EmailIcon = styled(Icons)`
  color: var(--color-neutral-70);
`;

export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
export const EmailText = styled(Typography)`
  color: var(--color-neutral-60);
`;

export const FirstColumnCell = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 400px;
`;

export const FirstColumnCellData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10px;
`;

export const StyledTableOptions = styled.div`
  display: flex;
  alignitems: center;
  gap: 36px;
`;
