import styled from 'styled-components';

export const StyledFooter = styled.footer`
  padding: ${({ theme }) => theme.spacings.xs2}
    ${({ theme }) => theme.spacings.xs};
  border-top: solid ${({ theme }) => theme.borders.width.xs}
    ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fill-available;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  z-index: 99;
`;

export const StyledLinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
