import { trpc } from '@/api/client';

export const useLastInvitation = () => {
  const { mutateAsync, isLoading } = trpc.getLastInvitation.useMutation();

  return {
    getLastInvitation: mutateAsync,
    loading: isLoading,
  };
};
