import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledModalHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledModalHeaderTitle = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledModalHeaderDescription = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledCellContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledCellTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledCellTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledCellDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledCellDescriptionContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const StyledCellIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[60]};
`;

export const StyledTableContainer = styled.div`
  min-height: 60vh;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCheckBoxContainer = styled.div`
  display: flex;
`;
