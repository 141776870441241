import { dayjs, IconButton } from '@flash-tecnologia/hros-web-ui-v2';

import {
  StyledModal,
  HeaderContainer,
  FooterContainer,
  ContentContainer,
  RadiosContainer,
  StyledText,
  StyledTitle,
  FlexContainer,
  StyledButton,
  StyledLinkButton,
  ColumnContainer,
  StyledRadio,
  DateContainer,
  StyledDatePicker,
  StyledTimePicker,
} from './styles';

import { useEditSchedule } from './useEditSchedule';

export const EditScheduleModal = ({
  open,
  onClose,
  name,
  resetTimer,
}: {
  open: boolean;
  onClose: () => void;
  name: string;
  resetTimer: () => void;
}) => {
  const { formik, loading } = useEditSchedule(onClose, resetTimer);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <HeaderContainer>
          <ColumnContainer>
            <StyledTitle setColor="neutral10" variant="headline6">
              Editar agendamento do convite de boas-vindas
            </StyledTitle>
            <StyledText setColor="neutral50" variant="body3">
              Configure o agendamento do convite de boas-vindas com o acesso à
              plataforma Flash {name ? `para ${name}` : ''}.
            </StyledText>
          </ColumnContainer>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
            style={{ marginTop: '-30px' }}
          />
        </HeaderContainer>
      }
      footer={
        <FooterContainer>
          <StyledLinkButton
            variant="default"
            children="Cancelar"
            onClick={onClose}
            style={{ alignSelf: 'center' }}
          />
          <StyledButton
            variant="primary"
            size="large"
            children="Confirmar"
            onClick={() => formik.handleSubmit()}
            loading={loading}
            style={{ alignSelf: 'center' }}
          />
        </FooterContainer>
      }
    >
      <ContentContainer>
        <StyledTitle setColor="neutral30" variant="body2">
          Editar agendamento do convite de boas-vindas
        </StyledTitle>
        <RadiosContainer>
          <FlexContainer>
            <StyledRadio
              size="small"
              name="sendInvite"
              checked={formik.values.type === 'SEND_INVITE'}
              onChange={() => {
                formik.setValues({
                  type: 'SEND_INVITE',
                  date: formik.values.date,
                });
              }}
            />{' '}
            <StyledText setColor="neutral30" variant="body4">
              Enviar imediamente
            </StyledText>
          </FlexContainer>
          <FlexContainer>
            <StyledRadio
              size="small"
              name="scheduleInvite"
              checked={formik.values.type === 'SCHEDULE_INVITE'}
              onChange={() => {
                formik.setValues({
                  type: 'SCHEDULE_INVITE',
                  date: formik.values.date,
                });
              }}
            />
            <StyledText setColor="neutral30" variant="body4">
              Agendar envio
            </StyledText>
          </FlexContainer>
        </RadiosContainer>

        {formik.values.type === 'SCHEDULE_INVITE' && (
          <DateContainer>
            <StyledDatePicker
              fromDate={dayjs().subtract(10, 'day').toDate()}
              label={'Data de envio'}
              value={formik.values.date}
              name={'date'}
              onDateChange={(e) => {
                formik.handleChange({
                  target: { id: 'date', value: e?.add(8, 'hours').toDate() },
                });
              }}
              error={formik.touched.date && Boolean(formik.errors.date)}
            />

            <StyledTimePicker
              label={'Hora de envio'}
              value={dayjs().hour(8).minute(0).second(0) as any}
              onChange={() => {}}
              disabled={true}
            />
          </DateContainer>
        )}
      </ContentContainer>
    </StyledModal>
  );
};
