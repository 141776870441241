import { trpc } from '@/api/client';
import { FormType } from '@/pages/PageTable/components/ModalCreateEmployee/context/FormContext';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

type UseCreateEmployeeProps = {
  options?: {
    onSuccess?: (employee: { id: string }) => void;
    onError?: () => void;
  };
};

export const useCreateEmployee = ({ options }: UseCreateEmployeeProps) => {
  const { selectedCompany } = useSelectedCompany();
  const { mutateAsync, isLoading } = trpc.createEmployee.useMutation({
    onSuccess: (data) => {
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
    onError: () => {
      if (options?.onError) {
        options.onError();
      }
    },
  });

  const createEmployee = async (employee: FormType) => {
    const employeeResponse = await mutateAsync({
      companyId: selectedCompany.id,
      ...employee,
    });

    return employeeResponse;
  };

  return {
    createEmployee,
    isLoading,
  };
};
