import { useCompanyPermissions } from '@/hooks/useCompanyPermissions';
import withAdditionalLabel from '@/pages/PageTable/components/ModalCreateEmployee/hocs/WithAdditionalLabel';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { useMemo } from 'react';
import { useForm } from '../../../../context/FormContext';
import { StyledLabel } from '../../styles';

const SelectFieldWithAdditionalLabel = withAdditionalLabel(SelectField);

export const SelectPermissionField = () => {
  const { permissions } = useCompanyPermissions();
  const { updateField, form, allFieldsDisabled } = useForm();

  const options = useMemo(
    () =>
      permissions
        ? permissions.map((permission: any) => ({
            label: permission.name,
            value: permission.id,
          }))
        : [],
    [permissions],
  );

  return (
    <SelectFieldWithAdditionalLabel
      fullWidth
      onSelectChange={(_, role) => updateField('roleId', role.value)}
      options={options}
      value={form.roleId}
      disabled={allFieldsDisabled}
      label="Nível de permissão"
      additionalLabel={
        <StyledLabel variant="caption">
          Para convidar administradores da plataforma, selecione o nível de
          permissão desejado.
        </StyledLabel>
      }
    />
  );
};
