import { Steps } from './components/Steps';
import { MultipleMigrationProvider } from './context/MultipleMigration.context';

export const PageMultipleMigration = () => {
  return (
    <MultipleMigrationProvider>
      <Steps />
    </MultipleMigrationProvider>
  );
};
