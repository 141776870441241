import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  BoxesContainer,
  StyledBigNumber,
  HeaderContainer,
  ReportContainer,
  StyledIconButton,
  StyledButton,
  StyledButtonContainer,
} from './styled';
import { StatusEnum } from '@/common/types/StatusEnum';
import { Filters } from '../Table';
import { useState } from 'react';
import { useGetEmployeesReportNumbers } from '../../hooks/useGetEmployeesReportNumbers';
import { setEventTracking } from '@/utils';

const BUTTON_EVENTS = {
  ACTIVE: 'employees_bignumbers_active_button_cliked',
  INVITATION_EXPIRED: 'employees_bignumbers_expired_button_cliked',
  PENDING_FIRST_ACCESS: 'employees_bignumbers_pending_button_cliked',
};

const BIG_NUMBER_TITLES = {
  ACTIVE: 'Pessoas com cadastro ativo',
  INVITATION_EXPIRED: 'Pessoas com convite de primeiro acesso expirado',
  PENDING_FIRST_ACCESS: 'Pessoas com primeiro acesso pendente',
};
const iconColors = { primary: '#F20D7A', secondary: '#FFE0EF' };

export const Report = ({ filters, setFilters, companyId, userId }: any) => {
  const handleFiltersChange = <TFilter extends keyof Filters>(
    value: Filters[TFilter],
  ) => {
    setFilters((prev: any) => ({
      ...prev,
      status: value,
    }));
  };
  const { reportNumbers } = useGetEmployeesReportNumbers(companyId);
  const [showCards, setShowCards] = useState(false);

  return (
    <ReportContainer>
      <HeaderContainer>
        <Typography variant="headline8" color="#53464F">
          Visão geral
        </Typography>
        {!showCards && (
          <StyledIconButton
            icon="IconChevronDown"
            variant="default"
            size="extra-small"
            onClick={() => setShowCards(!showCards)}
          />
        )}
        {showCards && (
          <StyledIconButton
            icon="IconChevronUp"
            variant="default"
            size="extra-small"
            onClick={() => setShowCards(!showCards)}
          />
        )}
      </HeaderContainer>
      <BoxesContainer showCards={showCards}>
        <StyledBigNumber
          icon="IconUsers"
          iconColors={iconColors}
          orientation="horizontal"
          value={reportNumbers?.[StatusEnum.ACTIVE] || '-'}
          title={BIG_NUMBER_TITLES.ACTIVE}
          button={Button({
            companyId,
            event: BUTTON_EVENTS.ACTIVE,
            onClickHandler: handleFiltersChange,
            selected: filters?.status === StatusEnum.ACTIVE,
            setEventTracking: setEventTracking,
            status: StatusEnum.ACTIVE,
            userId,
          })}
          type={StatusEnum.ACTIVE}
          filterActive={filters?.status === StatusEnum.ACTIVE}
        />
        <StyledBigNumber
          icon="IconLock"
          iconColors={iconColors}
          orientation="horizontal"
          value={reportNumbers?.[StatusEnum.INVITATION_EXPIRED] || '-'}
          title={BIG_NUMBER_TITLES.INVITATION_EXPIRED}
          button={Button({
            companyId,
            event: BUTTON_EVENTS.INVITATION_EXPIRED,
            onClickHandler: handleFiltersChange,
            selected: filters?.status === StatusEnum.INVITATION_EXPIRED,
            setEventTracking: setEventTracking,
            status: StatusEnum.INVITATION_EXPIRED,
            userId,
          })}
          type={StatusEnum.INVITATION_EXPIRED}
          filterActive={filters?.status === StatusEnum.INVITATION_EXPIRED}
        />
        <StyledBigNumber
          icon="IconMailOff"
          iconColors={iconColors}
          orientation="horizontal"
          value={reportNumbers?.[StatusEnum.INVITATION_SENT] || '-'}
          title={BIG_NUMBER_TITLES.PENDING_FIRST_ACCESS}
          button={Button({
            companyId,
            event: BUTTON_EVENTS.PENDING_FIRST_ACCESS,
            onClickHandler: handleFiltersChange,
            selected: filters?.status === StatusEnum.INVITATION_SENT,
            setEventTracking: setEventTracking,
            status: StatusEnum.INVITATION_SENT,
            userId,
          })}
          type="PENDING_FIRST_ACCESS"
          filterActive={filters?.status === StatusEnum.INVITATION_SENT}
        />
      </BoxesContainer>
    </ReportContainer>
  );
};

const Button = ({
  companyId,
  event,
  onClickHandler,
  selected,
  setEventTracking,
  status,
  userId,
}: any) => (
  <StyledButtonContainer>
    <StyledButton
      variant="default"
      onClick={() => {
        onClickHandler(selected ? null : status);
        setEventTracking(event, { employeeId: userId, companyId });
      }}
    >
      {selected ? 'Limpar filtro' : 'Visualizar pessoas com esse status'}
    </StyledButton>
  </StyledButtonContainer>
);
