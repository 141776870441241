import { MultiSelect, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const CustomFieldsContainer = styled.div`
  margin-top: 24px;
`;

export const StyledMultiSelect = styled(MultiSelect)`
  margin-bottom: 24px;
`;
