import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ExcelFilePage,
  TxtFilePage,
  DataAssociation,
  ImportFeedback,
  Permissions,
  UploadFile,
} from './steps';

import { Header } from './components';
import { EmployeesWorksheetSteps, ImportType } from './types';

export const PageEmployeesWorksheet = () => {
  const { operation, step } = useParams() as {
    operation: ImportType;
    step: string;
  };
  const navigate = useNavigate();

  const steps = useMemo(() => {
    switch (step) {
      case EmployeesWorksheetSteps.excelFileAttributes:
        return {
          step: 1,
          component: <ExcelFilePage operation={ImportType[operation]} />,
        };
      case EmployeesWorksheetSteps.textFileAttributes:
        return {
          step: 1,
          component: <TxtFilePage operation={ImportType[operation]} />,
        };
      case EmployeesWorksheetSteps.dataAssociation:
        return {
          step: 2,
          component: <DataAssociation operation={ImportType[operation]} />,
        };
      case EmployeesWorksheetSteps.permissions:
        return {
          step: 3,
          component: <Permissions operation={ImportType[operation]} />,
        };
      case EmployeesWorksheetSteps.importStatus:
        return {
          step: 4,
          component: (
            <ImportFeedback
              navigate={navigate}
              operation={ImportType[operation]}
            />
          ),
        };
      case EmployeesWorksheetSteps.uploadFile:
      default:
        return {
          step: 0,
          component: <UploadFile operation={ImportType[operation]} />,
        };
    }
  }, [step]);

  return (
    <>
      <Header operation={ImportType[operation]} handleStep={steps.step} />
      {steps.component}
    </>
  );
};
