import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { FooterCancelButton, FooterContainer } from './styled';
import { FooterInterface } from './types';

export const Footer = ({
  onClose,
  formId,
  loading,
  locked,
}: FooterInterface) => {
  return (
    <FooterContainer>
      <FooterCancelButton variant="neutral" onClick={onClose}>
        Cancelar
      </FooterCancelButton>
      <Button
        variant={'primary'}
        size="medium"
        disableRipple
        disableTouchRipple
        type="submit"
        form={formId}
        loading={loading}
        disabled={locked}
      >
        Concluir
        <Icons name="IconCheck" fill="transparent" />
      </Button>
    </FooterContainer>
  );
};
