import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import { StyledAside } from './styled';

export type AsideProps = {
  title: string;
  description: string;
};

export const Aside = ({ title, description }: AsideProps) => {
  const theme = useTheme();

  return (
    <StyledAside>
      <Typography
        variant="headline7"
        variantColor={theme.colors.secondary[50]}
        weight={700}
      >
        {title}
      </Typography>
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral[50]}
        weight={400}
      >
        {description}
      </Typography>
    </StyledAside>
  );
};
