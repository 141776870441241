import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.borders.radius.pill} !important;
`;
