import { trpc } from 'src/api/client';

type UseFindEmployeeContactsOptions = {
  onFound?: (data: 'email' | 'phone' | 'both') => void;
  onNotFound?: () => void;
};

const cleanPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/[+\-\s]/g, '');

export const useFindEmployeeContacts = ({
  onFound,
  onNotFound,
}: UseFindEmployeeContactsOptions) => {
  const { mutateAsync } = trpc.getEmployeesPaginated.useMutation();

  const findEmployeeContacts = async (phoneNumber?: string, email?: string) => {
    const requests = [] as { type: string; promise: Promise<any> }[];

    if (phoneNumber) {
      const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
      requests.push({
        type: 'phone',
        promise: mutateAsync({
          pageSize: 1,
          page: 1,
          searchQuery: cleanedPhoneNumber,
          status: '-INACTIVE',
        }),
      });
    }

    if (email) {
      requests.push({
        type: 'email',
        promise: mutateAsync({
          pageSize: 1,
          page: 1,
          searchQuery: email,
        }),
      });
    }

    const results = await Promise.all(requests.map((req) => req.promise));

    let hasEmail = false;
    let hasPhone = false;

    results.forEach((result, index) => {
      const requestType = requests[index].type;
      if (result?.employees?.length > 0) {
        if (requestType === 'email') hasEmail = true;
        if (requestType === 'phone') hasPhone = true;
      }
    });

    if (hasEmail && hasPhone) {
      onFound?.('both');
    } else if (hasEmail) {
      onFound?.('email');
    } else if (hasPhone) {
      onFound?.('phone');
    } else {
      onNotFound?.();
    }
  };

  return { findEmployeeContacts };
};
