import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledFooter, StyledLinkButtonWrapper } from './styled';
import { useNavigate } from 'react-router-dom';
import {
  MigrationStepEnum,
  useMultipleMigrationContext,
} from '@/pages/PageMultipleMigration/context/MultipleMigration.context';
import { useMemo } from 'react';

export const Footer = () => {
  const navigate = useNavigate();
  const { employeeIds, setStep, selectedCompany } =
    useMultipleMigrationContext();

  const disabledButton = useMemo(
    () => employeeIds.length === 0 || !selectedCompany,
    [employeeIds, selectedCompany],
  );

  return (
    <StyledFooter>
      <StyledLinkButtonWrapper>
        <LinkButton variant="neutral" onClick={() => navigate(-1)}>
          Voltar
        </LinkButton>
      </StyledLinkButtonWrapper>
      <Button
        onClick={() => setStep(MigrationStepEnum.PROCESSING)}
        disabled={disabledButton}
        size="medium"
        variant="primary"
        variantType="default"
      >
        Continuar
        <Icons name="IconArrowRight" />
      </Button>
    </StyledFooter>
  );
};
