import { useEffect, useState } from 'react';
import {
  AuthenticatedUser,
  useSelectedCompany,
  getAccessTokenPayloadSync,
  getAuthenticatedUser,
} from '@flash-tecnologia/hros-web-utility';

interface SelectedCompany {
  id: string;
  name: string;
  registrationNumber: string;
}

export const useSession = () => {
  const [authUser, setAuthUser] = useState<AuthenticatedUser>();
  const { selectedCompany } = useSelectedCompany();
  const accessTokenPayload = getAccessTokenPayloadSync();
  const company = selectedCompany as SelectedCompany;

  useEffect(() => {
    async function getAuthUser() {
      const authenticatedUser = await getAuthenticatedUser();
      setAuthUser(authenticatedUser);
    }

    getAuthUser();
  }, []);

  return {
    authUser,
    userId: accessTokenPayload?.employeeId as string,
    economicGroupId: accessTokenPayload.economicGroupId,
    companyId: company.id,
    name: company.name,
    registrationNumber: company.registrationNumber,
  };
};
