import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { ErrorBoundary } from './utils/ErrorBoundary';
import { ImportType } from './pages/PageEmployeesWorksheet/types';
import { QueryClientProvider } from '@tanstack/react-query';
import { trpc, trpcClient, queryClient } from './api/client';
import { ImportFeedback } from './pages/PageEmployeesWorksheet/steps';
import { NavigateFunction } from 'react-router-dom';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;

function WorksheetImportFeedback(props: {
  operation: ImportType;
  navigate: NavigateFunction;
  newImportUrl?: string;
  redirectBackTo?: string;
}) {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ImportFeedback
          operation={props.operation}
          navigate={props.navigate}
          newImportUrl={props.newImportUrl}
          redirectBackTo={props.redirectBackTo}
        />
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export { WorksheetImportFeedback };
