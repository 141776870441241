import { Checkbox, TextFieldProps } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledCheckBoxContainer,
  StyledCheckBoxLabel,
  StyledTextField,
  StyledTextFieldContainer,
} from './styles';

type WithCheckBoxProps = {
  checkboxLabel: string;
  onCheckBoxChange: () => void;
  disableCheckbox?: boolean;
};

export function withCheckBox<P extends TextFieldProps>(
  WrappedComponent: React.ComponentType<P>,
) {
  return (props: Omit<P, keyof WithCheckBoxProps>) => {
    return <WrappedComponent {...(props as P)} />;
  };
}

export const TextFieldWithCheckBox: React.FC<
  TextFieldProps & WithCheckBoxProps
> = (props) => {
  return (
    <StyledTextFieldContainer>
      <StyledTextField {...props} />
      <StyledCheckBoxContainer>
        <Checkbox
          disabled={props.disableCheckbox}
          size="small"
          onChange={() => props.onCheckBoxChange()}
        />
        <StyledCheckBoxLabel
          disabled={props.disableCheckbox}
          variant="body3"
          weight={600}
        >
          {props.checkboxLabel}
        </StyledCheckBoxLabel>
      </StyledCheckBoxContainer>
    </StyledTextFieldContainer>
  );
};
