import { ImportType } from '@/pages/PageEmployeesWorksheet/types';
import { Card, IconContainer, StyledIcon, TextContainer } from '../../styled';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { worksheetComponentInfo } from '@/pages/PageEmployeesWorksheet/componentsText';

interface IWaitingFeedback {
  operation: ImportType;
}

export const WaitingFeedback = ({ operation }: IWaitingFeedback) => {
  return (
    <Card>
      <TextContainer>
        <IconContainer setColor="secondary95">
          <StyledIcon
            name="IconRefresh"
            fill="none"
            setColor="primary"
            size={32}
            loading={true}
          />
        </IconContainer>
        <StyledTitle
          setColor="secondary50"
          variant="body1"
          children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.title}`}
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.description}`}
        />
      </TextContainer>
    </Card>
  );
};
