import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';

import {
  AlertWrapper,
  AlterIcon,
  Container,
  TextWrapper,
  Subtitle,
  Title,
  StyledModal,
  Warning,
} from './styled';
import { ModalWarningInterface } from './types';
import { Footer } from './components/Footer';

export const ModalWarning = ({
  isOpen,
  loading,
  title,
  description,
  onClose,
  onSubmit,
}: ModalWarningInterface) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer onClose={onClose} loading={loading} onSubmit={onSubmit} />
      }
    >
      <Container>
        <TextWrapper>
          <AlertWrapper>
            <AlterIcon name="IconAlertCircle" size={50} fill="transparent" />
          </AlertWrapper>
          <Warning variant="body3">Atenção!</Warning>
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
        </TextWrapper>
        <IconButton
          icon="IconX"
          onClick={onClose}
          variant="line"
          size="small"
        />
      </Container>
    </StyledModal>
  );
};
