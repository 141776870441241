import styled from 'styled-components';

import {
  DatePicker,
  Radio,
  TimePicker,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

export const CreateInviteContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;

  padding: 16px 24px;

  border: 1px solid var(--color-neutral-light4);
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  opacity: ${({ disabled }) => (disabled ? '.9' : 'inherit')};
`;

export const Title = styled(Typography)`
  color: var(--color-neutral-30);
`;

export const Subtitle = styled(Typography)`
  color: var(--color-neutral-50);
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  margin-top: 10px;
`;

export const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledRadio = styled(Radio)``;

export const RadioOptionText = styled(Typography)`
  padding-left: 9px;
`;

export const PickerWrapper = styled.div`
  display: flex;
  flex-direction: table-row;
  gap: 16px;

  .date-picker-container {
    flex: 1;
  }
`;

export const StyledDatePicker = styled(DatePicker)``;

export const StyledTimePicker = styled(TimePicker)``;
