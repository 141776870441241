import { trpc } from '@/api/client';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

type UseCreateDuplicationFlowProps = {
  company: {
    name: string;
    id: string;
    registrationNumber: string;
  };
  updateCalls: Dispatch<SetStateAction<number>>;
  errorsRef: MutableRefObject<
    {
      documentNumber: string;
      reason: string;
    }[]
  >;
};

export const useCreateDuplicationFlow = ({
  company,
  updateCalls,
  errorsRef,
}: UseCreateDuplicationFlowProps) => {
  const { mutateAsync: getEmployee } = trpc.getEmployee.useMutation();
  const { mutateAsync: findEmployees } =
    trpc.getEmployeesPaginated.useMutation();
  const { mutateAsync: createEmployee } = trpc.createEmployee.useMutation();

  const createDuplicationFlow = async (employeeId: string) => {
    const currentEmployee = await getEmployee({ employeeId });

    const existsInTargetCompany = await findEmployees({
      companyId: company.id,
      pageSize: 1,
      page: 1,
      documentNumbers: currentEmployee.documentNumber,
    });

    if (existsInTargetCompany.employees.length) {
      errorsRef.current.push({
        documentNumber: currentEmployee.documentNumber,
        reason: `O colaborador já existe na empresa ${company.name} - ${company.registrationNumber}.`,
      });
      updateCalls((calls) => calls + 1);
      return;
    }

    await createEmployee({
      companyId: company.id,
      name: currentEmployee.name,
      documentNumber: currentEmployee.documentNumber,
      email: currentEmployee.email,
      phone: currentEmployee.phoneNumber,
    });

    updateCalls((calls) => calls + 1);
  };

  return { createDuplicationFlow };
};
