import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { css, keyframes } from 'styled-components';

const bounceIn = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
  }
  30% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  50% {
    opacity: 1;
    transform: scale3d(0.95, 0.95, 0.95);
  }
  70% {
    transform: scale3d(1.02, 1.02, 1.02);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

export const StyledWarningCard = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  border: solid 1px ${({ theme }) => theme.colors.feedback.error[70]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  animation: ${bounceIn} 1s ease both;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StyledIconContainer = styled.div`
  height: 100%;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[10]};
`;

export const StyledLinkContainer = styled.div`
  display: flex;
`;

export const StyledLinkIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  margin-right: 5px;
`;

export const StyledSpinnerContainer = styled.div`
  margin-right: 5px;
`;
