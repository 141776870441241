import styled from 'styled-components';
import {
  Modal,
  Typography,
  TextField,
  Icons,
} from '@flash-tecnologia/hros-web-ui-v2';

export const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding: 24px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 430px;
  text-align: center;
  margin: 20px 48px 20px 88px;
`;

export const Warning = styled(Typography)`
  && {
    font-weight: 700;
    color: var(--color-feedback-error-pure);
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: var(--color-neutral-dark1);

  margin-bottom: 16px;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: var(--color-neutral-50);
`;

export const AlertWrapper = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-feedback-error-90);
  border-radius: 50%;
`;

export const AlterIcon = styled(Icons)`
  && {
    color: var(--color-feedback-error-40);
  }
`;
