import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledRadioContainer = styled.div<{
  checked: boolean;
  disabled?: boolean;
}>`
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.colors.primary : theme.colors.neutral[90]};
  padding: 16px 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  box-shadow: ${({ theme, checked }) =>
    checked ? `0 8px 16px ${theme.colors.primary}11` : 'none'};
  transition:
    box-shadow 0.5s ease,
    border 0.5s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  filter: ${({ disabled }) => (disabled ? 'grayscale(1)' : 'none')};
`;

export const StyledRadioText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledRadioTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledRadioDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
