import styled from 'styled-components';

export const StyledSelectInput = styled.div`
  .multi-select-custom-theme div.MuiFilledInput-root {
    padding: 2px 0 10px 15px !important;
    height: 58px !important;
  }

  .multi-select-custom-theme label {
    padding-top: 2px !important;
    padding-left: 2px !important;
  }

  .MuiChip-root.multi-select-tag {
    margin-top: 0px !important;
    transform: translateY(16px) translateX(-5px);
    height: 24px !important;
    padding: 3px 8px !important;
  }

  .MuiChip-label {
    padding-left: 0px !important;
  }

  .MuiChip-deleteIcon {
    margin-right: 0px !important;
  }

  .MuiFilledInput-input {
    padding-left: 0px !important;
    margin-top: 0px !important;
    transform: translateY(14px);
  }
`;
