import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledTextContainer,
  StyledText,
  StyledWarningCard,
  StyledIconContainer,
} from './styles';
import { ReactNode } from 'react';

type WarningCardProps = {
  title: string;
  description: string;
  action?: ReactNode;
};

export const WarningCard = ({
  title,
  description,
  action,
}: WarningCardProps) => {
  return (
    <StyledWarningCard>
      <StyledIconContainer>
        <PillButton
          size="medium"
          type="primary"
          icon="IconSwitch3"
          variant="error"
        />
      </StyledIconContainer>
      <StyledTextContainer>
        <StyledText variant="body3" weight={600}>
          {title}
        </StyledText>
        <StyledText variant="body4" weight={400}>
          {description}
        </StyledText>
        {action}
      </StyledTextContainer>
    </StyledWarningCard>
  );
};
