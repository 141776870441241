import React, { createContext, useContext, useState, ReactNode } from 'react';

export enum MigrationStepEnum {
  INPUT = 'INPUT',
  PROCESSING = 'PROCESSING',
  RESULT = 'RESULT',
}

export enum MigrationType {
  'DUPLICATE' = 'DUPLICATE',
  'MIGRATE' = 'MIGRATE',
}

interface MultipleMigrationContextType {
  step: MigrationStepEnum;
  setStep: (value: MigrationStepEnum) => void;
  migrationType: MigrationType;
  setMigrationType: (value: MigrationType) => void;
  employeeIds: string[];
  setEmployeeIds: (value: string[]) => void;
  selectedCompany:
    | {
        id: string;
        name: string;
        registrationNumber: string;
      }
    | undefined;
  setSelectedCompany: (value: {
    id: string;
    name: string;
    registrationNumber: string;
  }) => void;
  errors: { documentNumber: string; reason: string }[];
  setErrors: React.Dispatch<
    React.SetStateAction<
      {
        documentNumber: string;
        reason: string;
      }[]
    >
  >;
}

const MultipleMigrationContext = createContext<
  MultipleMigrationContextType | undefined
>(undefined);

interface MultipleMigrationProviderProps {
  children: ReactNode;
}

export const MultipleMigrationProvider = ({
  children,
}: MultipleMigrationProviderProps) => {
  const [step, setStep] = useState<MigrationStepEnum>(MigrationStepEnum.INPUT);
  const [migrationType, setMigrationType] = useState<MigrationType>(
    MigrationType.MIGRATE,
  );
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<{
    id: string;
    name: string;
    registrationNumber: string;
  }>();
  const [errors, setErrors] = useState<
    { documentNumber: string; reason: string }[]
  >([]);

  return (
    <MultipleMigrationContext.Provider
      value={{
        step,
        setStep,
        migrationType,
        setMigrationType,
        employeeIds,
        setEmployeeIds,
        setSelectedCompany,
        selectedCompany,
        errors,
        setErrors,
      }}
    >
      {children}
    </MultipleMigrationContext.Provider>
  );
};

export const useMultipleMigrationContext = () => {
  const context = useContext(MultipleMigrationContext);

  if (!context) {
    throw new Error(
      'useMultipleMigrationContext must be used within a MultipleMigrationProvider',
    );
  }

  return context;
};
