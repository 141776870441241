export const worksheetComponentInfo = {
  insert: {
    header: {
      title: 'Enviar convite para várias pessoas',
      breadCrumbs: {
        first: 'Pessoas',
        second: 'Adicionar várias pessoas',
      },
      stepper: [
        'Envie os dados',
        'Atributos do arquivo',
        'Associe as informações',
        'Permissão e convite',
        'Status do processamento',
      ],
    },
    uploadFile: {
      title: 'Envio do arquivo',
      description: [
        'Defina qual tipo de planilha será utilizado e faça o envio do seu arquivo preenchido.',
        'Nos próximos passos você poderá conferir tudo e corrigir possíveis erros.',
      ],
      warningMessage: {
        title:
          'Preencha E-mail ou Celular corretamente para garantir o envio dos convites de primeiro acesso.',
        description: [
          'O E-mail ou Celular informados serão utilizados no envio do convite de acesso ao App e Plataforma Flash para que a pessoa realize seu cadastro. Por isso, você deve informar ao menos um dos dois.',
          'Caso preencha ambos os dados, daremos preferência para o envio do convite por e-mail, que possui uma taxa de sucesso maior.',
        ],
      },
      chooseImportType: {
        title: 'Tipo de cadastro',
        description:
          'Oferecemos os modelos para cadastro simples, para empresas que usam recursos básicos e outro complementar, com mais detalhes para quem precisa de funcionalidades e integrações avançadas.',
        simpleImport: 'Cadastro simples',
        simpleImportDescription:
          'Dados básicos para cadastro da sua equipe nas plataformas Flash',
        completeImport: 'Cadastro completo',
        completeImportDescription:
          'Inclui informações adicionais essenciais para funcionamento de outros módulos',
      },
      chooseFileType: {
        title: 'Escolha o modelo de arquivo',
        description:
          'Você pode baixar e utilizar um modelo de arquivo da Flash ou enviar um arquivo já usado pela sua empresa.',
      },
    },
    excelFilePage: {
      title: 'Atributos do arquivo',
      description:
        'Precisamos entender sobre algumas características do seu arquivo antes de continuarmos com a importação. Complete os dados com atenção antes de prosseguir.',
      card: {
        title: 'Cabeçalho do arquivo',
        description: '',
      },
    },
    processmentStatusPage: {
      mainCard: {
        success: {
          singular: 'pessoa foi importada com sucesso.',
          plural: 'pessoas importadas com sucesso.',
        },
        error: {
          singular: 'pessoa não pode ser importada.',
          plural: 'pessoas não puderam ser importadas.',
        },
        processing: {
          singular: 'linha processada.',
          plural: 'linhas processadas.',
        },
        infoContainer: {
          successTitle: 'Sucesso na importação!',
          successDescription:
            'pessoas cadastradas com sucesso. Confira na lista de pessoas os cadastros importados.',
          error: 'Alguns cadastros não puderam ser importados.',
          fileError:
            'Confira no arquivo abaixo os erros. Você deverá refazer a importação dessas pessoas.',
        },
        status: {
          title: 'Em processamento',
          description:
            'Assim que o processamento do seu arquivo for finalizado, notificaremos aqui na plataforma o resultado da importação :)',
        },
      },
      mainContainer: {
        title: 'Status da importação',
        description:
          'Acompanhe aqui o status da sua importação. Esse processo pode demorar alguns minutos, mas você pode fechar essa página e continuar usando a plataforma normalmente!',
      },
      abortImportButton: 'Interromper importação',
      newImportButton: 'Nova importação',
      goBackButton: 'Voltar para a lista de pessoas',
    },
    cancelProcessment: {
      title: 'Atenção!',
      confirm: 'Tem certeza que deseja interromper processamento?',
      warning:
        'O processamento ainda não foi concluído, se você abandonar o processo antes de finalizar, poderá perder alguns dados.',
      button: 'Interromper processamento',
    },
    progressCards: {
      processing: {
        title: 'Importação de pessoas em andamento',
        description:
          'Enquanto isso, você pode continuar navegando normalmente. Assim que sua importação estiver concluída, notificaremos aqui na plataforma :)',
      },
      success: {
        title: 'Importação finalizada com sucesso',
        description: 'Confira na tabela abaixo os cadastros importados.',
      },
      successWithErrors: {
        title:
          'Processamento finalizado. Alguns cadastros não puderam ser importados.',
        success: {
          singular: 'pessoa foi importada com sucesso.',
          plural: 'pessoas foram importadas com sucesso.',
        },
        error: {
          singular: 'pessoa não pode ser importada.',
          plural: 'pessoas não puderam ser importadas.',
        },
      },
    },
  },
  update: {
    header: {
      title: 'Editar via planilha',
      breadCrumbs: {
        first: 'Pessoas',
        second: 'Editar via planilha',
      },
      stepper: [
        'Envie os dados',
        'Atributos do arquivo',
        'Associe as informações',
        'Status do processamento',
      ],
    },
    uploadFile: {
      title: 'Envio do arquivo',
      description: [
        'Defina qual tipo de planilha será utilizado e faça o envio do seu arquivo preenchido.',
        'Nos próximos passos você poderá conferir tudo e corrigir possíveis erros.',
      ],
      warningMessage: {
        title:
          'Preencha E-mail ou Celular corretamente para garantir o envio dos convites de primeiro acesso.',
        description: [
          'O E-mail ou Celular informados serão utilizados no envio do convite de acesso ao App e Plataforma Flash para que a pessoa realize seu cadastro. Por isso, você deve informar ao menos um dos dois.',
          'Caso preencha ambos os dados, daremos preferência para o envio do convite por e-mail, que possui uma taxa de sucesso maior.',
        ],
      },
      chooseImportType: {
        title: 'Tipo de edição',
        description:
          'Oferecemos os modelos para edição simples, para empresas que usam recursos básicos e outro complementar, com mais detalhes para quem precisa de funcionalidades e integrações avançadas.',
        simpleImport: 'Edição simples',
        simpleImportDescription:
          'Dados básicos para edição da sua equipe nas plataformas Flash',
        completeImport: 'Edição completa',
        completeImportDescription:
          'Inclui informações adicionais essenciais para funcionamento de outros módulos',
      },
      chooseFileType: {
        title: 'Tipo de edição',
        description:
          'Oferecemos os modelos para edição simples, para empresas que usam recursos básicos e outro complementar, com mais detalhes para quem precisa de funcionalidades e integrações avançadas.',
      },
    },
    excelFilePage: {
      title: 'Atributos do arquivo',
      description:
        'Precisamos entender sobre algumas características do seu arquivo antes de continuarmos com a edição. Complete os dados com atenção antes de prosseguir.',
      card: {
        title: 'Cabeçalho do arquivo',
        description: `O cabeçalho do arquivo é a descrição de um determinado grupo de dados. Exemplo: “Nome completo”, "CPF", ”E-mail” e "Telefone” formam o cabeçalho do arquivo.`,
      },
    },
    processmentStatusPage: {
      mainCard: {
        success: {
          singular: 'pessoa foi editada com sucesso.',
          plural: 'pessoas foram editadas com sucesso.',
        },
        error: {
          singular: 'pessoa não pode ser editada.',
          plural: 'pessoas não puderam ser editadas.',
        },
        processing: {
          singular: 'linha processada.',
          plural: 'linhas processadas.',
        },
        infoContainer: {
          successTitle: 'Sucesso na edição!',
          successDescription:
            'pessoas editadas com sucesso. Confira na lista de pessoas os cadastros editados.',
          error: 'Alguns cadastros não puderam ser editados.',
          fileError:
            'Confira no arquivo abaixo os erros. Você deverá refazer a edição dessas pessoas.',
        },
        status: {
          title: 'Em processamento',
          description:
            'Assim que o processamento do seu arquivo for finalizado, notificaremos aqui na plataforma o resultado da edição :)',
        },
      },
      mainContainer: {
        title: 'Status da edição',
        description:
          'Acompanhe aqui o status da sua edição. Esse processo pode demorar alguns minutos, mas você pode fechar essa página e continuar usando a plataforma normalmente!',
      },
      abortImportButton: 'Interromper importação',
      newImportButton: 'Nova importação',
      goBackButton: 'Voltar para a lista de pessoas',
    },
    cancelProcessment: {
      title: 'Atenção!',
      confirm: 'Tem certeza que deseja interromper processamento?',
      warning:
        'O processamento ainda não foi concluído, se você abandonar o processo antes de finalizar, poderá perder alguns dados.',
      button: 'Interromper processamento',
    },
    progressCards: {
      processing: {
        title: 'Edição de pessoas em andamento',
        description:
          'Enquanto isso, você pode continuar navegando normalmente. Assim que sua edição estiver concluída, notificaremos aqui na plataforma :)',
      },
      success: {
        title: 'Edição finalizada com sucesso',
        description: 'Confira na tabela abaixo os cadastros editados.',
      },
      successWithErrors: {
        title:
          'Processamento finalizado. Alguns cadastros não puderam ser editados.',
        success: {
          singular: 'pessoa foi editada com sucesso.',
          plural: 'pessoas foram editadas com sucesso.',
        },
        error: {
          singular: 'pessoa não pode ser editada.',
          plural: 'pessoas não puderam ser editadas.',
        },
      },
    },
  },
  deactivate: {
    header: {
      title: 'Desativar pessoas por planilha',
      breadCrumbs: {
        first: 'Pessoas',
        second: 'Adicionar várias pessoas',
      },
      stepper: [
        'Envie os dados',
        'Atributos do arquivo',
        'Associe as informações',
        'Permissão e convite',
        'Status do processamento',
      ],
    },
    uploadFile: {
      title: 'Envio do arquivo',
      description: [
        'Defina qual tipo de planilha será utilizado e faça o envio do seu arquivo preenchido.',
        'Nos próximos passos você poderá conferir tudo e corrigir possíveis erros.',
      ],
      warningMessage: null,
      chooseImportType: null,
      chooseFileType: {
        title: 'Escolha o modelo de arquivo',
        description:
          'Você pode baixar e utilizar um modelo de arquivo da Flash ou enviar um arquivo já usado pela sua empresa.',
      },
    },
    excelFilePage: {
      title: 'Atributos do arquivo',
      description:
        'Precisamos entender sobre algumas características do seu arquivo antes de continuarmos com a importação. Complete os dados com atenção antes de prosseguir.',
      card: {
        title: 'Cabeçalho do arquivo',
        description: '',
      },
    },
    processmentStatusPage: {
      mainCard: {
        success: {
          singular: 'pessoa foi desativada com sucesso.',
          plural: 'pessoas desativadas com sucesso.',
        },
        error: {
          singular: 'pessoa não pode ser desativada.',
          plural: 'pessoas não puderam ser desativadas.',
        },
        processing: {
          singular: 'linha processada.',
          plural: 'linhas processadas.',
        },
        infoContainer: {
          successTitle: 'Sucesso na desativação!',
          successDescription: 'pessoas desativadas com sucesso.',
          error: 'Alguns cadastros não puderam ser desativados.',
          fileError:
            'Confira no arquivo abaixo os erros. Você deverá refazer a desativação dessas pessoas.',
        },
        status: {
          title: 'Em processamento',
          description:
            'Assim que o processamento do seu arquivo for finalizado, notificaremos aqui na plataforma o resultado da desativação',
        },
      },
      mainContainer: {
        title: 'Status da desativação',
        description:
          'Acompanhe aqui o status da desativação. Esse processo pode demorar alguns minutos, mas você pode fechar essa página e continuar usando a plataforma normalmente!',
      },
      abortImportButton: 'Interromper importação',
      newImportButton: 'Nova importação',
      goBackButton: 'Voltar para a lista de pessoas',
    },
    cancelProcessment: {
      title: 'Atenção!',
      confirm: 'Tem certeza que deseja interromper processamento?',
      warning:
        'O processamento ainda não foi concluído, se você abandonar o processo antes de finalizar, poderá perder alguns dados.',
      button: 'Interromper processamento',
    },
    progressCards: {
      processing: {
        title: 'Desativação de pessoas em andamento',
        description:
          'Enquanto isso, você pode continuar navegando normalmente. Assim que sua importação estiver concluída, notificaremos aqui na plataforma :)',
      },
      success: {
        title: 'Desativação finalizada com sucesso',
        description:
          'Os cadastros desativados não devem mais aparecer na tabela abaixo.',
      },
      successWithErrors: {
        title:
          'Processamento finalizado. Alguns cadastros não puderam ser desativados.',
        success: {
          singular: 'pessoa foi desativada com sucesso.',
          plural: 'pessoas foram desativadas com sucesso.',
        },
        error: {
          singular: 'pessoa não pode ser desativada.',
          plural: 'pessoas não puderam ser desativadas.',
        },
      },
    },
  },
};
