import {
  IconsProps,
  PillButton,
  Radio,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledDescription,
  StyledFormWrapper,
  StyledIconWrapper,
  StyledTextWrapper,
  StyledTitle,
} from './styles';
import { ReactNode } from 'react';

type FormOptionsProps = {
  title: ReactNode;
  description: ReactNode;
  icon: IconsProps['name'];
  active: boolean;
  onClick: () => void;
};

export const FormOption = ({
  title,
  description,
  icon,
  active,
  onClick,
}: FormOptionsProps) => {
  return (
    <StyledFormWrapper active={active} onClick={onClick}>
      <Radio checked={active} onChange={onClick} />
      <StyledTextWrapper>
        <StyledTitle weight={600} variant="body3">
          {title}
        </StyledTitle>
        <StyledDescription variant="body4">{description}</StyledDescription>
      </StyledTextWrapper>
      <StyledIconWrapper>
        <PillButton type="primary" size="medium" variant="pink" icon={icon} />
      </StyledIconWrapper>
    </StyledFormWrapper>
  );
};
