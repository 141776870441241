import styled from 'styled-components';

export const StyledRadioWrapper = styled.div`
  display: flex;
  align-items: space-between;
  gap: 24px;
`;

export const StyledTypeFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;
