import { NavigateFunction } from 'react-router-dom';
import { StyledIcon, ButtomContainer, StyledButton } from '../../styled';
import {
  ImportStatus,
  ImportType,
  EmployeesWorksheetSteps,
} from '@/pages/PageEmployeesWorksheet/types';
import { getWorksheetUrl } from '../../../../helpers/getWorksheetUrl';
import { worksheetComponentInfo } from '@/pages/PageEmployeesWorksheet/componentsText';

interface IFooterFeedback {
  importStatus: ImportStatus;
  navigate: NavigateFunction;
  operation: ImportType;
  setOpenAbortModal: (input: boolean) => void;
  newImportUrl?: string;
  redirectBackTo?: string;
}

export const FooterFeedback = ({
  importStatus,
  navigate,
  operation,
  setOpenAbortModal,
  newImportUrl,
  redirectBackTo,
}: IFooterFeedback) => {
  const allowAbortImport = () => {
    if (
      [ImportStatus.PROCESSING, ImportStatus.WAITING].includes(importStatus)
    ) {
      return (
        <StyledButton
          style={{ alignSelf: 'center' }}
          variant="secondary"
          size="large"
          onClick={() => {
            setOpenAbortModal(true);
          }}
        >
          <StyledIcon name="IconX" />
          {`${worksheetComponentInfo[operation].processmentStatusPage.abortImportButton}`}
        </StyledButton>
      );
    }
  };

  const allowNewImport = () => {
    if (
      [
        ImportStatus.ABORTED,
        ImportStatus.ERRORED,
        ImportStatus.SUCCESS,
        ImportStatus.SUCCESS_WITH_ERRORS,
      ].includes(importStatus)
    ) {
      return (
        <StyledButton
          style={{ alignSelf: 'center' }}
          variant="secondary"
          size="large"
          onClick={() => {
            newImportUrl
              ? navigate(newImportUrl)
              : navigate(
                  getWorksheetUrl(
                    operation,
                    EmployeesWorksheetSteps.uploadFile,
                  ),
                );
          }}
        >
          <StyledIcon name="IconX" />
          {`${worksheetComponentInfo[operation].processmentStatusPage.newImportButton}`}
        </StyledButton>
      );
    }
  };
  return (
    <ButtomContainer>
      {allowAbortImport()}
      {allowNewImport()}
      <StyledButton
        style={{ alignSelf: 'center' }}
        variant="primary"
        size="large"
        onClick={() => {
          redirectBackTo
            ? navigate(redirectBackTo)
            : navigate('/employees', {
                state: { openCard: true },
              });
        }}
      >
        <StyledIcon name="IconArrowLeft" />
        {`${worksheetComponentInfo[operation].processmentStatusPage.goBackButton}`}
      </StyledButton>
    </ButtomContainer>
  );
};
