import { useMemo, useState } from 'react';
import {
  StyledDateContainer,
  StyledDateOptionContainer,
  StyledDatePicker,
  StyledInvitationCardContainer,
  StyledOptionsContainer,
  StyledRadio,
  StyledRadioContainer,
  StyledRadioLabel,
  StyledSubtitle,
  StyledTextContainer,
  StyledTimePicker,
  StyledTitle,
} from './styles';
import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { useForm } from '../../../../context/FormContext';

export const InvitationCard = () => {
  const {
    updateField,
    allFieldsDisabled,
    setInvitationType,
    invitationType,
    errors,
  } = useForm();

  const defaultTime = useMemo(
    () => dayjs().set('hour', 8).set('minute', 0).set('second', 0),
    [],
  );

  return (
    <StyledInvitationCardContainer>
      <StyledTextContainer>
        <StyledTitle variant="body3">
          Defina quando o convite será enviado
        </StyledTitle>
        <StyledSubtitle variant="body4">
          Escolha em que momento a pessoa receberá o convite para criar sua
          conta.
        </StyledSubtitle>
      </StyledTextContainer>
      <StyledOptionsContainer>
        <StyledRadioContainer
          onClick={() => {
            setInvitationType('INSTANTLY');
            updateField('invitationDate', undefined);
          }}
        >
          <StyledRadio checked={invitationType === 'INSTANTLY'} />
          <StyledRadioLabel variant="body3">
            Enviar imediamente
          </StyledRadioLabel>
        </StyledRadioContainer>
        <StyledRadioContainer onClick={() => setInvitationType('SCHEDULED')}>
          <StyledRadio checked={invitationType === 'SCHEDULED'} />
          <StyledRadioLabel variant="body3">Agendar envio</StyledRadioLabel>
        </StyledRadioContainer>
      </StyledOptionsContainer>
      {invitationType === 'SCHEDULED' && (
        <StyledDateContainer>
          <StyledDateOptionContainer>
            <StyledDatePicker
              helperText={errors['invitationDate']}
              error={!!errors['invitationDate']}
              fromDate={dayjs().add(1, 'day').toDate()}
              disabled={allFieldsDisabled}
              label="Data de envio"
              onDateChange={(value) =>
                updateField('invitationDate', value?.toISOString())
              }
            />
          </StyledDateOptionContainer>
          <StyledDateOptionContainer>
            <StyledTimePicker
              onChange={() => null}
              disabled
              value={defaultTime as any}
              label="Hora de envio"
            />
          </StyledDateOptionContainer>
        </StyledDateContainer>
      )}
    </StyledInvitationCardContainer>
  );
};
