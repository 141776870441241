import { LinkButton, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledButtonContainer,
  StyledCloseIcon,
  StyledIconContainer,
  StyledMainContainer,
  StyledNotificationCard,
  StyledText,
  StyledTextContainer,
} from './styles';
import { useState } from 'react';
import { useMultipleMigrationContext } from '@/pages/PageMultipleMigration/context/MultipleMigration.context';
import { useNavigate } from 'react-router-dom';

export const NotificationCard = () => {
  const [open, setOpen] = useState(true);
  const { employeeIds } = useMultipleMigrationContext();
  const navigate = useNavigate();

  return open ? (
    <StyledNotificationCard>
      <StyledIconContainer>
        <PillButton
          size="medium"
          type="primary"
          variant="pink"
          icon="IconInfoCircle"
        />
      </StyledIconContainer>
      <StyledMainContainer>
        <StyledTextContainer>
          <StyledText variant="body3" weight={600}>
            {employeeIds.length} pessoas selecionadas
          </StyledText>
          <StyledText variant="body4" weight={400}>
            Você selecionou {employeeIds.length} pessoas para serem
            movimentadas. Clique no botão abaixo para ver as pessoas
            selecionadas ou volte na tela anterior para refazer a seleção.
          </StyledText>
        </StyledTextContainer>
        <StyledButtonContainer>
          <LinkButton onClick={() => navigate('/employees')} variant="default">
            Voltar para a seleção de pessoas
          </LinkButton>
        </StyledButtonContainer>
      </StyledMainContainer>
      <StyledCloseIcon onClick={() => setOpen(false)} name="IconX" size={16} />
    </StyledNotificationCard>
  ) : null;
};
