import { useMemo } from 'react';
import { FormStep } from './FormStep/FormStep';
import {
  MigrationStepEnum,
  useMultipleMigrationContext,
} from '../../context/MultipleMigration.context';
import { ProcessingStep } from './ProcessingStep';
import { ResultStep } from './ResultStep';

export const Steps = () => {
  const { step } = useMultipleMigrationContext();

  const steps = useMemo(
    () => ({
      [MigrationStepEnum.INPUT]: <FormStep />,
      [MigrationStepEnum.PROCESSING]: <ProcessingStep />,
      [MigrationStepEnum.RESULT]: <ResultStep />,
    }),
    [step],
  );

  return steps[step];
};
