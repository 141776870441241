import { useSearchEmployee } from '@/pages/PageTable/components/ModalCreateEmployee/hooks/useSearchEmployee';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { useMemo } from 'react';
import { useForm } from '../../../../context/FormContext';
import { useSession } from '@/common/user';

export const SelectManagerField = () => {
  const { data, search } = useSearchEmployee();
  const { userId } = useSession();
  const { updateField, allFieldsDisabled } = useForm();

  const options = useMemo(
    () =>
      data
        ? data.employees
            .filter((employee) => employee.id !== userId)
            .map((employee) => ({
              label: employee.name,
              value: employee.id,
            }))
        : [],
    [data],
  );

  return (
    <SelectField
      searchable
      onSelectChange={(_, manager) => updateField('managerId', manager.value)}
      options={options}
      fullWidth
      label="Líder imediato"
      disabled={allFieldsDisabled}
      onChange={(e) => search(e.target.value)}
    />
  );
};
