import { trpc } from '@/api/client';
import { useState } from 'react';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { ImportStatus } from '../types';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import dispatchToast from '@utils/dispatchToast';

interface IUseGetImport {
  pollInterval?: number;
}

interface Import {
  importId: string;
  action: string;
  fileId: string;
  fileName: string;
  status: ImportStatus;
  processedRows: number;
  totalRows: number;
  errors: {
    row: number;
    message: string;
  }[];
}

export const useGetImport = ({ pollInterval }: IUseGetImport) => {
  const TEN_SECONDS = 10 * 1000;
  const importId = getFromLS('importId');
  const [importProcess, setImportProcess] = useState<Import | null>(null);
  const [successRowsQt, setSuccessRowsQt] = useState(0);
  const [failedRowsQt, setFailedRowsQt] = useState(0);
  const [importStatus, setImportStatus] = useState<ImportStatus>(
    ImportStatus.WAITING,
  );
  const queryEnableCheck = ({
    importId,
    importStatus,
  }: {
    importId: string;
    importStatus: ImportStatus;
  }): boolean => {
    if (!importId || Object.keys(importId).length === 0) return false;
    if (
      [
        ImportStatus.SUCCESS,
        ImportStatus.ERRORED,
        ImportStatus.ABORTED,
        ImportStatus.SUCCESS_WITH_ERRORS,
      ].includes(importStatus)
    )
      return false;
    return true;
  };

  const { data, isLoading, refetch } = trpc.getImport.useQuery(importId, {
    onSuccess(data) {
      setImportProcess(data as Import);
      if (data.status === ImportStatus.SUCCESS && data.errors.length) {
        setImportStatus(ImportStatus.SUCCESS_WITH_ERRORS);
      } else {
        setImportStatus(data.status as ImportStatus);
      }
      setFailedRowsQt(data.errors.length);
      setSuccessRowsQt(Math.max(data.processedRows - data.errors.length, 0));
    },
    onError(error) {
      setImportStatus(ImportStatus.ERRORED);
      ErrorBoundary.captureException({ ...error, name: 'GetImportError' });
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao buscar informações de processamento.',
      });
    },
    enabled: queryEnableCheck({ importId, importStatus }),
    refetchInterval: pollInterval ? pollInterval : TEN_SECONDS,
  });
  return {
    importProcess,
    importStatus,
    successRowsQt,
    failedRowsQt,
    setImportStatus,
    isLoading,
    getImportRefetch: refetch,
  };
};
