import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import mime from 'mime-types';
import * as yup from 'yup';
import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { XLSXReader } from '@Utils/XLSXReader';
import { TXTReader } from '@Utils/TXTReader';
import { ButtonArea } from '../../components';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { getWorksheetUrl } from '../../helpers/getWorksheetUrl';
import { EmployeesWorksheetSteps, ImportType } from '../../types';
import {
  CreateImportInput,
  useCreateImport,
} from '../../hooks/useCreateImport';
import {
  ChooseImportType,
  ChooseFileType,
  GeneralInstructions,
} from './components';
import { CreateImportPayloadBuilder } from '../../helpers/createImportPayloadBuilder';
import {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  TextContainer,
  ContentContainer,
  StyledDropZone,
} from './styled';
import { WorksheetOverwriteWarning } from '../../modals/WorksheetOverwriteWarning';

const validationSchema = yup.object({
  simpleImport: yup.boolean().required('Selecione uma opção'),
  completeImport: yup.boolean().required('Selecione uma opção'),
  defaultModel: yup.boolean().required('Selecione uma opção.'),
  userModel: yup.boolean().required('Selecione uma opção.'),
});

interface IUploadFileProps {
  operation: ImportType;
}

export const UploadFile = ({ operation }: IUploadFileProps) => {
  const navigate = useNavigate();

  const [fileType, setFileType] = useState('');
  const [invalidFile, setInvalidFile] = useState(true);
  const [droppedFile, setDroppedFile] = useState<any[]>([]);
  const [showOverwriteModal, setShowOverwriteModal] = useState(false);
  const { isLoading, importId, createImportSheet } = useCreateImport();

  const formik = useFormik({
    initialValues: {
      simpleImport: true,
      completeImport: false,
      defaultModel: true,
      userModel: false,
    },
    validationSchema: validationSchema,

    onSubmit: () => {
      if (formik.values.defaultModel) {
        processData(fileType);
      } else {
        if (fileType === 'xlsx') {
          navigate(
            getWorksheetUrl(
              operation,
              EmployeesWorksheetSteps.excelFileAttributes,
            ),
            {
              state: { droppedFile, simpleImport: formik.values.simpleImport },
            },
          );
        } else {
          navigate(
            getWorksheetUrl(
              operation,
              EmployeesWorksheetSteps.textFileAttributes,
            ),
            {
              state: { droppedFile, simpleImport: formik.values.simpleImport },
            },
          );
        }
      }
    },
  });

  useEffect(() => {
    if (importId)
      navigate(
        getWorksheetUrl(operation, EmployeesWorksheetSteps.importStatus),
        { state: { refresh: true } },
      );
  }, [importId]);

  const processData = async (fileType: string) => {
    let result = [];
    if (fileType === 'xlsx') {
      result = await XLSXReader({
        droppedFiles: droppedFile,
      });
    }

    if (fileType === 'csv' || fileType === 'txt') {
      result = (await TXTReader({
        droppedFiles: droppedFile,
        separator: ',',
      })) as any;
    }
    let fileDictionary: CreateImportInput['fileDictionary'];
    formik.values.simpleImport && operation !== ImportType.deactivate
      ? (fileDictionary = CreateImportPayloadBuilder.simpleFileDictionary)
      : (fileDictionary = CreateImportPayloadBuilder.completeFileDictionary);

    if (operation === ImportType.deactivate)
      fileDictionary = CreateImportPayloadBuilder.deactivateFileDictionary;

    switch (operation) {
      case ImportType.insert:
        navigate(
          getWorksheetUrl(operation, EmployeesWorksheetSteps.permissions),
          {
            state: {
              droppedFile,
              dataTable: result,
              defaultConfig: formik.values.defaultModel,
              simpleImport: formik.values.simpleImport,
              fileDictionary,
            },
          },
        );
        break;
      case ImportType.update:
        const updatePayload: CreateImportInput =
          new CreateImportPayloadBuilder()
            .withActionConfig({ operation: ImportType.update })
            .withFileDictionary(fileDictionary)
            .build(droppedFile);
        await createImportSheet(updatePayload);
        break;
      case ImportType.deactivate:
        const deactivatePayload: CreateImportInput =
          new CreateImportPayloadBuilder()
            .withActionConfig({ operation: ImportType.deactivate })
            .withFileDictionary(fileDictionary)
            .build(droppedFile);
        await createImportSheet(deactivatePayload);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PageContainer>
        <MainContainer>
          <LeftColumn>
            <GeneralInstructions operation={operation} />
          </LeftColumn>
          <RightColumn>
            <ChooseImportType operation={operation} formik={formik} />
            <ChooseFileType operation={operation} formik={formik} />
            <Card data-onboarding="employee-management-upload-employee-list">
              <TextContainer>
                <StyledTitle
                  setColor="neutral30"
                  variant="body1"
                  children="Envie seu arquivo"
                />
                <StyledText
                  setColor={'neutral50'}
                  variant="body3"
                  children="Com o arquivo em mãos, agora é só anexar abaixo! "
                />
              </TextContainer>
              <ContentContainer>
                <StyledDropZone
                  style={{ width: '100%' }}
                  title="Seu arquivo"
                  accept={['xlsx', 'csv', 'txt']}
                  onChange={async (e) => {
                    const file = mime.extension(e[0]?.file.type);

                    if (!file) return;
                    setDroppedFile(e);
                    setFileType(file);
                    setInvalidFile(!!!file);
                  }}
                  onRemove={() => {
                    setInvalidFile(!invalidFile);
                  }}
                />
              </ContentContainer>
            </Card>
          </RightColumn>
          <WorksheetOverwriteWarning
            showModal={showOverwriteModal}
            setShowModal={setShowOverwriteModal}
            callback={formik.handleSubmit}
            isLoading={isLoading}
          />
        </MainContainer>
      </PageContainer>
      <ButtonArea
        disabled={invalidFile}
        variant="firstPage"
        loading={isLoading}
        onForward={() =>
          operation === ImportType.update && formik.values.completeImport
            ? setShowOverwriteModal(true)
            : formik.handleSubmit()
        }
        onCancel={() => navigate('/employees')}
      />
    </>
  );
};
