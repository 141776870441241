import {
  DatePicker,
  TimePicker,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Radio } from '@mui/material';
import styled from 'styled-components';

export const StyledInvitationCardContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 8px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledOptionsContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const StyledRadioContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`;

export const StyledRadioLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledRadio = styled(Radio)`
  padding: 0px;
`;

export const StyledDateContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: start;
`;

export const StyledDateOptionContainer = styled.div`
  width: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  flex: 1;
`;
export const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  flex: 1;
`;
