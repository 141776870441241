import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Link, useParams } from 'react-router-dom';
import { StyledBreadcrumbs, StyledIcon } from './styled';

export const EmployeePageBreadcrumbs = ({ name }: { name: string }) => {
  const { id } = useParams();
  if (id === 'me') return <></>;
  const breadcrumbs = [
    <Link to="/employees" key={'index-pessoas-0'}>
      <LinkButton variant="neutral" key={'index-pessoas-1'}>
        Pessoas
      </LinkButton>
    </Link>,
    <LinkButton variant="neutral" key={`index-pessoas-${name}`}>
      {name}
    </LinkButton>,
  ];

  return (
    <StyledBreadcrumbs
      separator={<StyledIcon name="IconChevronRight" fill="none" />}
      breadcrumbs={breadcrumbs}
    />
  );
};
