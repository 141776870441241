import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledFormWrapper = styled.div<{ active: boolean }>`
  padding: 16px 24px;
  border: solid 1px
    ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.neutral[80]};
  border-radius: 8px;
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
`;
export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
