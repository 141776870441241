import { trpc } from '@/api/client';

export const useCanMigrateEmployee = () => {
  const { mutateAsync, isLoading } = trpc.canMigrateEmployee.useMutation();

  return {
    canMigrate: mutateAsync,
    loading: isLoading,
  };
};
