import {
  IPermissionsFlags,
  getPermissionsFlags,
} from '@flash-tecnologia/hros-web-utility';

import { IToggle } from 'unleash-proxy-client';

import { useEffect, useState } from 'react';

type useUnleashTogglesProps = {
  companyId?: string;
  employeeId?: string;
  economicGroupId?: string;
};

export function useUnleashToggles({
  companyId = '',
  employeeId = '',
  economicGroupId = '',
}: useUnleashTogglesProps) {
  const [flags, setFlags] = useState<IPermissionsFlags['flags']>([]);
  const [loading, setLoading] = useState(false);

  const getUnleashToggle = async () => {
    setLoading(true);
    const { flags } = await getPermissionsFlags(
      companyId,
      employeeId,
      economicGroupId,
    );
    setFlags(flags || []);
    setLoading(false);
  };

  useEffect(() => {
    getUnleashToggle();
  }, [companyId, employeeId, economicGroupId]);
  return { flags, loading };
}

export function checkUnleashToggle(flagName: string, flagsUnleash: IToggle[]) {
  return flagsUnleash?.some((_flag) => _flag.name === flagName);
}
