import { useFindCompanyRoles } from '@/hooks/useFindCompanyRoles';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { useMemo } from 'react';
import { useForm } from '../../../../context/FormContext';

export const SelectRoleField = () => {
  const { roles } = useFindCompanyRoles();
  const { updateField, allFieldsDisabled } = useForm();

  const options = useMemo(
    () =>
      roles
        ? roles.map((role) => ({
            label: role.name,
            value: role.id,
          }))
        : [],
    [roles],
  );

  return (
    <SelectField
      disabled={allFieldsDisabled}
      onSelectChange={(_, role) => updateField('jobPositionId', role.value)}
      options={options}
      fullWidth
      label="Cargo"
    />
  );
};
