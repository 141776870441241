import {
  StyledModalHeaderDescription,
  StyledModalHeaderTitle,
  StyledModalHeaderTitleWrapper,
} from './styles';

export const CreateEmployeeModalHeader = () => {
  return (
    <StyledModalHeaderTitleWrapper>
      <StyledModalHeaderTitle variant="headline8" weight={700}>
        Enviar convite de primeiro acesso
      </StyledModalHeaderTitle>
      <StyledModalHeaderDescription variant="body4" weight={400}>
        Através desse convite de primeiro acesso, a pessoa irá criar sua conta
        para utilizar o app e plataforma da Flash.
      </StyledModalHeaderDescription>
    </StyledModalHeaderTitleWrapper>
  );
};
