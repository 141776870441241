import { Modal } from '@flash-tecnologia/hros-web-ui-v2';
import { Footer } from './components/Footer';
import { Form } from './components/Form';
import { Header } from './components/Header';
import { ModalAddEmployeeInterface } from './types';

export const ModalAddEmployee = ({
  isOpen,
  onClose,
  headerProps,
  footerProps,
  formProps,
}: ModalAddEmployeeInterface) => {
  return (
    <Modal.Root
      size="sm"
      open={isOpen}
      onClose={onClose}
      header={<Header {...headerProps} />}
      footer={<Footer {...footerProps} />}
    >
      <Form {...formProps} />
    </Modal.Root>
  );
};
