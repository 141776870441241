import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledText, StyledTitle } from '@/common/styles/general-styles';
import {
  StyledModal,
  SubtitleBold,
  MainContainer,
  FooterContainer,
  StyledButton,
  IconContainer,
  StyledIcon,
  HeaderContainer,
} from './styles';

interface IWorksheetOverwriteModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  callback: () => void;
  isLoading: boolean;
}
export const WorksheetOverwriteWarning = ({
  showModal,
  setShowModal,
  callback,
  isLoading,
}: IWorksheetOverwriteModalProps) => {
  const onClose = () => {
    setShowModal(false);
  };
  const Footer = (
    <FooterContainer>
      <LinkButton
        variant="default"
        style={{ alignSelf: 'center' }}
        onClick={onClose}
      >
        Cancelar
      </LinkButton>
      <StyledButton
        variant="primary"
        size="medium"
        style={{ alignSelf: 'center', color: 'black' }}
        onClick={callback}
        loading={isLoading}
        variantType="info"
      >
        Sim, enviar planilha
      </StyledButton>
    </FooterContainer>
  );

  return (
    <StyledModal footer={Footer} open={showModal} onClose={onClose}>
      <MainContainer>
        <HeaderContainer>
          <IconContainer>
            <StyledIcon
              name="IconAlertCircle"
              fill="none"
              size={24}
              stroke={'2px'}
            />
          </IconContainer>

          <StyledTitle
            setColor="feedback40"
            variant="body4"
            style={{
              textAlign: 'center',
              marginTop: '4px',
              marginBottom: '8px',
            }}
          >
            Atenção!
          </StyledTitle>

          <StyledTitle
            setColor="neutral10"
            variant="headline8"
            style={{
              textAlign: 'start',
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            Alerta de modificação de dados
          </StyledTitle>
        </HeaderContainer>

        <StyledText
          setColor="neutral50"
          variant="body4"
          style={{ textAlign: 'start', marginBottom: '15px' }}
        >
          Importar uma nova planilha{' '}
          <SubtitleBold> sobrescreverá </SubtitleBold>
          os dados existentes. Usuários atualmente em múltiplos departamentos ou
          grupos serão ajustados conforme a nova planilha.
        </StyledText>

        <StyledText
          setColor="neutral50"
          variant="body4"
          style={{ textAlign: 'start' }}
        >
          <SubtitleBold>Exemplo: </SubtitleBold>
          Se a nova planilha inclui apenas um departamento, o usuário será
          removido dos departamentos adicionais.
        </StyledText>
      </MainContainer>
    </StyledModal>
  );
};
